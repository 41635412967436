import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

const DebtAnnouncement = () => {
  const [companyNames, setCompanyNames] = useState([]);
  const [announcementData, setAnnouncementData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const receivedData = location.state?.data;
  console.log("...", receivedData);
  const { isDark } = useContext(ThemeContext);
  const [selectedCompany, setSelectedCompany] = useState(
    receivedData && receivedData.name ? receivedData.name : ""
  );
  const baseurl = process.env.REACT_APP_BASE_URL;

  // Function to save the token in local storage
  const saveTokenToLocalStorage = (token) => {
    localStorage.setItem("token", token);
    //console.log(token);
    setToken(token); // Optionally, you can also update state with the token
  };

  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          baseurl + "/nse-announcement/company-list",
          {
            headers: {
              "ngrok-skip-browser-warning": "application/json",
              Authorization: `Bearer ${token}`, // Set Authorization header with token
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company names");
        }
        const data = await response.json();
        setCompanyNames(data);
      } catch (error) {
        console.error("Error fetching company names:", error);
      }
    };

    fetchCompanyNames();
  }, []);

  // Fetch announcement data for selected company
  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const token = localStorage.getItem("authToken");
      //console.log(token);

      if (!selectedCompany) return;

      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          `${baseurl}/nse-announcement/${selectedCompany}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "application/json",

              Authorization: `Bearer ${token}`, // Set Authorization header with token
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Check if data is empty or malformed
        if (!data || data.length === 0) {
          throw new Error("Empty or invalid response from server");
        }

        setAnnouncementData(data);
      } catch (error) {
        console.error(
          `Error fetching announcement data for ${selectedCompany}:`,
          error
        );
        // Handle error state or display a message to the user
      }
    };

    fetchAnnouncementData();
  }, [selectedCompany]);

  const handleCompanyChange = (event) => {
    const companyName = event.target.value;
    setSelectedCompany(companyName);
  };

  const DownloadFile = () => {
    const csvRows = [];

    // Add the headers
    const headers = [
      "ID",
      "Name",
      "Subject",
      "Details",
      "Date",
      "Dissemination",
      "Difference",
      "Attachment",
    ];
    csvRows.push(headers.join(","));

    // Add the data
    announcementData.forEach((item) => {
      const row = [
        item.id,
        item.name,
        item.subject,
        item.details,
        item.date,
        item.dissemination,
        item.difference,
        item.attachment,
      ];
      csvRows.push(row.join(","));
    });

    // Create the CSV string
    const csvString = csvRows.join("\n");

    // Create a Blob object and a link element to trigger the download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "announcement.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleISINClick = (isin) => {
    navigate(`/debt/info?isin=${isin}`);
  };

  return (
    <div
      className='isin-container'
      style={{ backgroundColor: isDark && "#717171" }}
    >
      <div className='isin-header'>
        <h1>
          NSE Debt Announcements{" "}
          <a
            href='https://www.nseindia.com/companies-listing/corporate-filings-announcements'
            target='_blank'
          >
            LINK
          </a>{" "}
        </h1>
        {/* {companyNames.length > 0 && (
          <>
            <div className="flex">
              <select
                className="clickable isin-filters"
                onChange={handleCompanyChange}
              >
                <option value="">Select Company</option>
                {companyNames.map((company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </select>

              <button className="button" onClick={DownloadFile}>
                Download Csv
              </button>
            </div>
          </>
        )} */}
      </div>
      <div>
        <h2
          style={{
            color: isDark && "white",
          }}
        >
          Data Table
        </h2>
        <table
          className='isin-table'
          style={{
            backgroundColor: isDark && "gray",
            color: isDark && "white",
          }}
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Subject</th>
              <th>Details</th>
              <th>Date</th>
              <th>Dissemination</th>
              <th>Difference</th>
              <th>Attachment</th>
            </tr>
          </thead>
          <tbody>
            {announcementData.map((item, index) => (
              <tr key={index}>
                <td style={{ color: isDark && "white" }}>{item.id}</td>
                <td style={{ color: isDark && "white" }}>{item.name}</td>
                <td style={{ color: isDark && "white" }}>{item.subject}</td>
                <td style={{ color: isDark && "white" }}>{item.details}</td>
                <td style={{ color: isDark && "white" }}>{item.date}</td>
                <td style={{ color: isDark && "white" }}>
                  {item.dissemination}
                </td>
                <td style={{ color: isDark && "white" }}>{item.difference}</td>
                <td style={{ color: isDark && "white" }}>
                  <a
                    href={item.attachment}
                    target='_blank'
                    style={{ textDecoration: "none", borderRadius: "5px" }}
                  >
                    LINK
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DebtAnnouncement;
