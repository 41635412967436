import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "antd";

// Utility function to break camelCase keys into readable format
const camelCaseToReadable = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Handle cases like "HTMLParser"
    .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
};

const RBIStateGovSecurities = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [stateGovSecurities, setStateGovSecurities] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const fetchRBIStateGovSecurities = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        };
        const response = await axios.get(baseUrl + "/rbi/state-gov-sec", {
          headers,
        });
        console.log(response.data);
        setStateGovSecurities(response.data);

        // Dynamically create columns based on the keys of the first object
        if (response.data.length > 0) {
          const keys = Object.keys(response.data[0]);
          const dynamicColumns = keys.map((key) => ({
            title: camelCaseToReadable(key), // Use utility function for title
            dataIndex: key,
            key: key,
            render: (text) => (text === null ? "N/A" : text), // Handle null values
          }));
          setColumns(dynamicColumns);
        }
      } catch (error) {
        console.error("Error fetching RBI Gov Securities data:", error);
      }
    };

    fetchRBIStateGovSecurities();
  }, []);

  return (
    <div>
      <h1>Reserve Bank of India - State Government Securities</h1>
      <Table
        dataSource={stateGovSecurities} // Pass the data to the table
        columns={columns} // Define the columns dynamically
        rowKey="srNo" // Set the row key
      />
    </div>
  );
};

export default RBIStateGovSecurities;
