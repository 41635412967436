import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Button, Col, Row, Table, Typography } from "antd";
import "../../../src/CustomPagination.css";
import axios from "axios";
import { ThemeContext } from "../../context/ThemeContext";
const { Title } = Typography;

const Dashboard = () => {
  //const baseUrl = "https://c94d-152-58-22-225.ngrok-free.app";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const history = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [bondName, setBondName] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [newsData, setNews] = useState([]);
  const { isDark } = useContext(ThemeContext);

  const components = {
    table: (props) => (
      <table
        {...props}
        style={{
          backgroundColor: isDark ? "#3f3f3f" : "white",
          color: isDark ? "white" : "black",
          border: isDark && "1px solid gray",
        }}
      >
        {props.children}
      </table>
    ),
    body: {
      row: ({ className, ...restProps }) => (
        <tr
          {...restProps}
          style={{
            backgroundColor: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        />
      ),
      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
    header: {
      row: (props) => (
        <tr
          {...props}
          style={{
            background: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        >
          {props.children}
        </tr>
      ),

      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            fontWeight: "bold",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
  };

  const theme = {
    color: isDark && "white",
  };

  const [listData, setlistData] = useState([
    {
      key: 1,
      name: "Amazon.com, Inc.",
      meta: "AMZN",
      price: "$102.24",
      subPrice: "+3.02",
    },
    {
      key: 2,
      name: "Coca-Cola Co",
      meta: "KO",
      price: "$60.49",
      subPrice: "−0.32",
    },
    {
      key: 3,
      name: "Bayerische Motoren Werke AG",
      meta: "BMW",
      price: "$92.94",
      subPrice: "+0.59",
    },
    {
      key: 4,
      name: "Microsoft Corp",
      meta: "MSFT",
      price: "$248.16",
      subPrice: "+0.16",
    },
    {
      key: 5,
      name: "United Parcel Service, Inc.",
      meta: "UPS",
      price: "$182.09",
      subPrice: "+2.39",
    },
    {
      key: 6,
      name: "Mastercard Inc",
      meta: "MA",
      price: "$374.03",
      subPrice: "−3.21",
    },
  ]);

  const detailsBtnStyle = {
    backgroundColor: isDark ? "gray" : "#0ACF83",
    marginLeft: "5px",
    cursor: "pointer",
    textAlign: "center",
  };

  const financialBtnStyle = {
    backgroundColor: isDark ? "gray" : "#E8E8E8",
    color: isDark ? "white" : "#6C757D",
  };

  const govSchemesData = [
    {
      key: 1,
      schemeName: "Reserve Bank of India-Saving Bonds",
    },
    {
      key: 2,
      schemeName: "Reserve Bank of India-State Government Securities",
    },
    {
      key: 3,
      schemeName: "Reserve Bank of India-Government Securities",
    },
    {
      key: 4,
      schemeName: "Post Office Savings Schemes",
    },
  ];

  const governmentSchemesColumns = [
    {
      title: "Scheme Name",
      dataIndex: "schemeName",
      key: "schemeName",
    },

    {
      title: "Details",
      key: "view",
      render: (data) => {
        return (
          <div>
            <span>
              <Button
                onClick={() => history(`/d/gov/${data.key}`)}
                type="primary"
                style={detailsBtnStyle}
              >
                View
              </Button>
            </span>
          </div>
        );
      },
    },
  ];

  const columns = [
    {
      title: "Bond Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "",
      key: "details",
      render: (data) => {
        // console.log(data)
        return (
          <div>
            <span>
              <Button
                onClick={() =>
                  history("/d/dash/details", {
                    state: { data: data, key: "details" },
                  })
                }
                type="primary"
                style={detailsBtnStyle}
              >
                Details
              </Button>
            </span>
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "financials",
      key: "financial",
      render: () => {
        return (
          <Button style={financialBtnStyle} type="primary">
            Financials
          </Button>
        );
      },
    },
  ];

  const newsColumns = [
    {
      title: "Title",
      key: "action",
      width: 200,
      render: (data) => {
        return (
          <div>
            <p
              style={{
                cursor: "pointer", // Set cursor to pointer
                color: isDark ? "white" : "blue",
              }}
              onClick={() => history("/d/news", { state: { data: data } })}
            >
              {data.title}
            </p>
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,

        //Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiQWRpdHlhIiwicm9sbG5vIjoxLCJzdWIiOiJhZGl0eWFzYWxndWRlMTExMkBnbWFpbC5jb20iLCJpYXQiOjE3MjA0MzMwMTAsImV4cCI6MTcyMDUxOTQxMH0.2qQKOwjdh7R7l-iniMwK6s62Qvlmzie-LnSu-6vNwh0`,
        // You can add more headers if needed
      };
      const response = await axios.get(`${baseUrl}/ratings`, { headers });

      // Assuming your API returns a JSON response
      if (response && response.data) {
        const uniqueNamesSet = new Set();
        const normalizeAndLowercase = (name) => name.toLowerCase();

        // Filter out duplicates case-insensitively
        const uniqueData = response.data.filter((item) => {
          const normalizedLowercaseName = normalizeAndLowercase(item.name);
          const isUnique = !uniqueNamesSet.has(normalizedLowercaseName);
          if (isUnique) {
            uniqueNamesSet.add(normalizedLowercaseName);
          }
          return isUnique;
        });
        setBondName(uniqueData);
        setSearchData(uniqueData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchNews = async () => {
    let mintData = [],
      financialData = [],
      bsData = [];
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/news/mint`, { headers });
      if (response && response.data) {
        mintData = response.data;
      }
      const response1 = await axios.get(`${baseUrl}/news/financial`, {
        headers,
      });
      if (response1 && response1.data) {
        financialData = response1.data;
      }
      const response2 = await axios.get(`${baseUrl}/news/bs`, {
        headers,
      });
      if (response2 && response2.data) {
        bsData = response2.data;
      }
      setNews([...mintData, ...financialData, ...bsData]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchNews();
  }, []);
  const onSearch = (val) => {
    if (val == "") {
      setBondName(searchData);
    } else {
      let search = searchData;
      const searchTermLower = val.toLowerCase(); // Convert the search term to lowercase for case-insensitive search

      const results = search.filter((item) =>
        item.name.toLowerCase().includes(searchTermLower)
      );

      setBondName(results);
    }
  };

  return (
    <div>
      <Row>
        <Col span={15}>
          <Title
            style={{ marginTop: "20px", color: isDark && "white" }}
            level={2}
          >
            BONDS
          </Title>
          {/* <Row gutter={16}>
            <Col span={6}>
              <Input
                suffix={<SearchOutlined />}
                placeholder="Search for..."
                onChange={(event) => onSearch(event.target.value)}
                style={{ width: "100%" }}
              />
            </Col>
          </Row> */}
          <Table
            dataSource={bondName}
            columns={columns}
            loading={loading}
            components={components}
            className={isDark && "custom-pagination"}

            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: event => { history('/rating', { state: { data: record } })} ,// click row
            //     style: { cursor: 'pointer' }
            //   };
            // }}
          />
          <Title
            style={{ marginTop: "20px", color: isDark && "white" }}
            level={2}
          >
            Government Schemes
          </Title>
          <Table
            dataSource={govSchemesData}
            columns={governmentSchemesColumns}
            loading={loading}
            components={components}
            className={isDark && "custom-pagination"}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={8}>
          <Title
            style={{ marginTop: "20px", color: isDark && "white" }}
            level={2}
          >
            News
          </Title>
          <Table
            dataSource={newsData}
            columns={newsColumns}
            components={components}
            pagination={{ pageSize: 7 }}
            className="custom-pagination"
          />

          {/* {newsData && newsData.length > 0 && newsData.map((val) => {
              return (
                <div style={{}}>
                  <p style={{
                    cursor: 'pointer', // Set cursor to pointer
                    color: 'blue'
                  }} onClick={()=>history('/news', { state: { data: val } })}>{val.title}</p>
                        <Divider/>
                </div>
              )
            })} */}
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
{
  /* <List.Item.Meta
                    avatar={
                      <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    }
                    title={item.name}
                    description={item.meta}
                  />
                  <div>
                    <p>{item.price}</p>
                    <p>{item.subPrice}</p>
                  </div> */
}
