import { Card, Col, Row, Button } from "antd";
import "./Plans.css";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useCallback, useContext } from "react";

import axios from "axios";
import { ThemeContext } from "../../context/ThemeContext";
const baseUrl = process.env.REACT_APP_RAZORPAY_BASE_URL;

const Plans = () => {
  const history = useNavigate();
  const [Razorpay] = useRazorpay();

  const { isDark } = useContext(ThemeContext);

  const handlePaymentSuccess = async (event) => {
    console.log("Payment successful:2", event);
    const response = await axios.put(
      baseUrl + "/api/order/update",
      {
        razorpayOrderId: event.razorpay_order_id,
        razorpayPaymentId: event.razorpay_payment_id,
        razorpaySignature: event.razorpay_signature,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("razorpayAuthToken"),
        },
      }
    );
    console.log("Response from update:", response.data);
  };
  const handlePayments = useCallback(() => {
    const options = {
      key: "rzp_test_eSjj6LTpslzcRf",
      amount: "3000", // amount in smallest currency unit (e.g., paisa for INR)
      currency: "INR",
      name: "SVIRTZ",
      description: "Test Transaction",
      image:
        "https://media.licdn.com/dms/image/C4E1BAQGRQsJ8GrZdEg/company-background_10000/0/1617100387085/svirtz_technology_solutions_pvt_ltd_cover?e=1723478400&v=beta&t=XX7QZjINMx7Aso7LgrBjerMasnjI_LcWhzq3gxAL488",
      order_id: localStorage.getItem("orderId"),
      handler: (res) => {
        handlePaymentSuccess(res);
      },
      prefill: {
        name: "Aditya Salgude",
        email: "asalgude@gmail.com",
        contact: "8656252456",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);
  const handleBuy = (plan) => {
    createOrder(plan);

    // Add payment logic here
  };
  const createOrder = async (price) => {
    console.log("print", price);
    try {
      const response = await axios.post(
        baseUrl + "/api/order/create",
        {
          customerName: "Aditya's order",
          email: "asalgude@gmail.com",
          phoneNumber: "9999999999",
          amount: price,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("razorpayAuthToken"),
          },
        }
      );

      console.log("Response:", response.data);
      localStorage.setItem("orderId", response.data.razorpayOrderId);

      // Redirect to /payment route
      handlePayments();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const plans = [
    {
      title: "Basic",
      price: "1000",
      period: "/month",
      details: "1 License Minimum",
      additionalInfo: "",
      features: [
        "Unlimited calling",
        "Unlimited SMS",
        "Custom voicemail greeting",
        "Voicemail transcription",
        "G Suite & Office 365 integrations",
        "Fax (add-on)",
        "Custom off-hours routing",
        "-",
        "-",
      ],
      buttonType: "primary",
      tryButton: "Buy now",
    },
    {
      title: "Standard",
      price: "2000",
      period: "/month",
      details: "3 License Minimum",
      additionalInfo: "",
      features: [
        "All Basic plan features, plus:",
        "Local number support in 50+ countries",
        "CRM integrations (Salesforce, Hubspot)",
        "24/7 phone support",
        "Unlimited meetings",
        "Hold queues",
        "International SMS",
        "Deskphone support",
        "-",
      ],

      buttonType: "primary",
      tryButton: "Buy now",
      isPopular: true, // This is for the highlighted card
    },
    {
      title: "Premium",
      price: "3000",
      period: "/month",
      details: "100 License Minimum",
      features: [
        "All Standard features, plus:",
        "100% uptime Service Level Agreements (SLA)",
        "Extensions",
        "Unlimited office locations",
        "Enhanced 24/7 Phone Support",
        "Unlimited ring groups (departments)",
        "Azure Integration",
        "IAM/SSO Integrations (Okta, OneLogin)",
        "Retention policies",
      ],
      tryButton: "Buy now",
      buttonType: "default",
    },
  ];

  return (
    <Row gutter={16} className='plans-container container'>
      {plans.map((plan) => (
        <Col span={8} key={plan.title}>
          <Card
            className={`plan-card ${plan.isPopular ? "popular-plan" : ""}`}
            style={{
              backgroundColor: isDark && "#717171",
              boxShadow:
                isDark &&
                "0 1px 2px 0 #00000045, 0 1px 6px -1px #00000045, 0 2px 4px 0 #00000045",
            }}
            bordered={false}
            title={
              <div className='plan-title' style={{ color: isDark && "white" }}>
                {plan.title}
              </div>
            }
          >
            <div className='plan-price' style={{ color: isDark && "white" }}>
              RS. {plan.price}{" "}
              <span
                className='plan-period'
                style={{ color: isDark && "white" }}
              >
                {plan.period}
              </span>
            </div>
            {plan.additionalInfo && (
              <div
                className='plan-additional-info'
                style={{ color: isDark && "white" }}
              >
                {plan.additionalInfo}
              </div>
            )}
            <div className='plan-details' style={{ color: isDark && "white" }}>
              {plan.details}
            </div>
            <ul className='plan-features'>
              {plan.features.map((feature, index) => (
                <li key={index} style={{ color: isDark && "white" }}>
                  {feature}
                </li>
              ))}
            </ul>
            {plan.tryButton && (
              <Button
                className='try-button'
                type='default'
                onClick={() => handleBuy(plan.price)}
              >
                {plan.tryButton}
              </Button>
            )}
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Plans;
