import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

function CardsSlider({ cardsInfo, navigate }) {
  const { isDark } = useContext(ThemeContext);

  const moduleStyle = {
    boxShadow: isDark && "17px 14px 15px -7px #0000007a",
    border: isDark && "1px solid #6f696933",
    background: isDark && "#717171",
  };

  return (
    <>
      <div className='scroll'>
        <div className='cardsContainer'>
          {cardsInfo.map((card) => {
            return (
              <div
                className='module'
                style={moduleStyle}
                onClick={() => navigate(card.navigate)}
              >
                <div
                  className='top'
                  style={{ background: isDark && "#28282887" }}
                >
                  <div
                    className='articles'
                    style={{ color: isDark ? "white" : "#3f3f3f" }}
                  >
                    {card.articles} {card.articles != null && "Articles"}
                  </div>
                  <div
                    class='tooltip1'
                    style={{ color: isDark ? "white" : "black" }}
                  >
                    {card.info}
                  </div>
                </div>
                <div className='bottom'>{card.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CardsSlider;
