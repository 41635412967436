import { useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Col, Input, Row, Table, Typography, Divider } from "antd";
import { ThemeContext } from "../context/ThemeContext.js";
import { SearchOutlined } from "@ant-design/icons";
import "../../src/CustomPagination.css";

import axios from "axios";

const { Title } = Typography;

const News = () => {
  const location = useLocation();
  const key = location.state && location.state.key ? true : false;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [newsData, setNewsData] = useState(!key ? location.state?.data : {});
  const [news, setNews] = useState([]);
  const { isDark } = useContext(ThemeContext);
  const newsColumns = [
    {
      title: "Related News",
      key: "action",
      width: 200,
      render: (data) => {
        // console.log(data)
        return (
          <div>
            <p
              style={{
                cursor: "pointer", // Set cursor to pointer
                color: isDark ? "white" : "blue",
              }}
              onClick={() => setNewsData(data)}
            >
              {data.title}
            </p>
          </div>
        );
      },
    },
  ];
  const fetchNews = async () => {
    let mintData = [],
      financialData = [],
      bsData = [],
      economicsData = [];

    if (key) {
      try {
        const headers = {
          "ngrok-skip-browser-warning": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        };
        const response = await axios.get(
          `${baseUrl}/news/mint/${location.state.data.name}`,
          { headers }
        );
        if (response && response.data) {
          mintData = response.data;
        }
        const response1 = await axios.get(
          `${baseUrl}/news/financial/${location.state.data.name}`,
          { headers }
        );
        if (response1 && response1.data) {
          financialData = response1.data;
        }
        const response2 = await axios.get(
          `${baseUrl}/news/bs/${location.state.data.name}`,
          { headers }
        );
        if (response2 && response2.data) {
          bsData = response2.data;
        }
        const response3 = await axios.get(
          `${baseUrl}/news/economics/${location.state.data.name}`,
          { headers }
        );
        if (response3 && response3.data) {
          economicsData = response3.data[0].data;
        }
        const data = [
          ...mintData,
          ...financialData,
          ...bsData,
          ...economicsData,
        ];
        setNews(data);
        setNewsData(data.length > 0 ? data[0] : {});
        if (data.length == 0) {
          const response = await axios.get(`${baseUrl}/news/mint`, { headers });
          if (response && response.data) {
            mintData = response.data;
          }
          const response1 = await axios.get(`${baseUrl}/news/financial`, {
            headers,
          });
          if (response1 && response1.data) {
            financialData = response1.data;
          }
          const response2 = await axios.get(`${baseUrl}/news/bs`, {
            headers,
          });
          if (response2 && response2.data) {
            financialData = response2.data;
          }
          const response3 = await axios.get(`${baseUrl}/news/economics`, {
            headers,
          });
          if (response3 && response3.data) {
            economicsData = response3.data[0].data;
          }
          const data = [
            ...mintData,
            ...financialData,
            ...bsData,
            ...economicsData,
          ];
          setNews(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    } else {
      try {
        const headers = {
          "ngrok-skip-browser-warning": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        };
        const response = await axios.get(
          `${baseUrl}/news/mint/${newsData.name}`,
          { headers }
        );
        if (response && response.data) {
          mintData = response.data;
        }
        const response1 = await axios.get(
          `${baseUrl}/news/financial/${newsData.name}`,
          { headers }
        );
        if (response1 && response1.data) {
          financialData = response1.data;
        }
        const response2 = await axios.get(
          `${baseUrl}/news/bs/${newsData.name}`,
          { headers }
        );
        if (response2 && response2.data) {
          bsData = response2.data;
        }
        const response3 = await axios.get(
          `${baseUrl}/news/economics/${newsData.name}`,
          { headers }
        );
        if (response3 && response3.data) {
          economicsData = response3.data[0].data;
        }
        setNews([...mintData, ...financialData, ...bsData, ...economicsData]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    }
  };
  useEffect(() => {
    fetchNews();
  }, []);

  const components = {
    table: (props) => (
      <table
        {...props}
        style={{
          backgroundColor: isDark ? "#3f3f3f" : "white",
          color: isDark ? "white" : "black",
          border: isDark && "1px solid gray",
        }}
      >
        {props.children}
      </table>
    ),
    body: {
      row: ({ className, ...restProps }) => (
        <tr
          {...restProps}
          style={{
            backgroundColor: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        />
      ),
      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
    header: {
      row: (props) => (
        <tr
          {...props}
          style={{
            background: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        >
          {props.children}
        </tr>
      ),

      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            fontWeight: "bold",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
  };

  const onSearch = (val) => {};
  return (
    <div style={{ color: isDark && "white" }}>
      <div style={{ display: "flex" }}>
        <Title level={2} style={{ color: isDark && "white" }}>
          {key ? location.state.data.name : newsData && newsData.name}
        </Title>
      </div>

      <Row>
        <Col span={1}></Col>
        <Col span={14}>
          {newsData && newsData.hasOwnProperty("name") ? (
            <>
              <div
                style={{
                  width: "100%",
                  overflow: "hidden",
                }}
                className='full-width-image-container'
              >
                <img
                  style={{
                    width: "100%",
                    height: "350px",
                    display: "block",
                  }}
                  src='https://i.insider.com/6113ebd909c63400193334c3?width=700'
                  alt='Full Width'
                  className='full-width-image'
                />
              </div>
              <div style={{ padding: "5px" }}>
                <Title level={2} style={{ color: isDark && "white" }}>
                  {newsData && newsData.title}
                </Title>

                {/* <p style={{ fontSize: "15px",marginTop:"5px" }}>
                               {newsData && newsData.timestamp}{" "} {newsData && newsData.content}
                            </p> */}

                <p style={{ whiteSpace: "pre-line", marginTop: "5px" }}>
                  {newsData && newsData.timestamp}{" "}
                  {newsData && newsData.content ? (
                    newsData.content.startsWith("http") ? (
                      <div>
                        <div className='text-xl'>Read News in details :-</div>{" "}
                        <a
                          className='text-lg'
                          href={newsData.content}
                          target='_blank'
                        >
                          {newsData.content}
                        </a>
                      </div>
                    ) : (
                      <>
                        <div>{newsData.content.split(" ")[0]}</div>
                        <div>
                          {newsData.content
                            .split(" ")
                            .slice(1)
                            .join(" ")
                            .replace(/\n/g, "\n\n")}
                        </div>
                      </>
                    )
                  ) : (
                    "--"
                  )}
                </p>
              </div>
              <Divider />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "500px",
              }}
            >
              <Title level={4}>News Not Available !!</Title>
            </div>
          )}
        </Col>
        <Col span={1}></Col>
        <Col span={1}>
          <Divider type='vertical' style={{ height: "700px" }} />
        </Col>
        <Col span={7}>
          <Input
            suffix={<SearchOutlined />}
            placeholder='Search for...'
            onChange={(event) => onSearch(event.target.value)}
            style={{
              width: "100%",
              backgroundColor: isDark && "gray",
              color: isDark && "white",
            }}
          />
          <div style={{ padding: "7px" }}>
            <Table
              dataSource={news}
              columns={newsColumns}
              pagination={{ pageSize: 7 }}
              components={components}
              className={isDark && "custom-pagination"}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default News;
