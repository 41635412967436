import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Input, Button, Form, message, Result } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";

const NewsApproval = () => {
  const history = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [newsData, setNewsData] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [isAllApproved, setIsAllApproved] = useState(false); // To track if all news is approved
  const [form] = Form.useForm();

  // Extract data from location state
  const location = useLocation();
  const data = location.state?.data;

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const headers = {
          "ngrok-skip-browser-warning": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        };
        const response = await axios.get(`${baseUrl}/news/${data}/mongo`, {
          headers,
        });
        if (response && response.data) {
          setNewsData(response.data);
          // Set the initial form values to the first news item
          form.setFieldsValue(response.data[0]);
        }
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };
    fetchNewsData();
  }, [data, baseUrl, form]);

  const onFinish = async (values) => {
    try {
      // Send the updated news to the backend
      const updatedNews = {
        ...newsData[currentNewsIndex],
        ...values,
      };
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      updatedNews.oldTitle = newsData[currentNewsIndex].title;
      if (!newsData[currentNewsIndex].name) {
        newsData[currentNewsIndex].name = newsData[currentNewsIndex].company;
      }
      await axios.post(`${baseUrl}/news/add/${data}`, updatedNews, { headers });
      message.success("News published successfully!");

      // Move to the next news item or mark all as approved
      if (currentNewsIndex < newsData.length - 1) {
        const newIndex = currentNewsIndex + 1;
        setCurrentNewsIndex(newIndex);
        form.setFieldsValue(newsData[newIndex]); // Update form with the new news item
      } else {
        setIsAllApproved(true); // Mark all as approved
      }
    } catch (error) {
      console.error("Error publishing news:", error);
      message.error("Failed to publish news.");
    }
  };

  const handleNextNews = () => {
    if (currentNewsIndex < newsData.length - 1) {
      const newIndex = currentNewsIndex + 1;
      setCurrentNewsIndex(newIndex);
      form.setFieldsValue(newsData[newIndex]); // Update form with the new news item
    } else {
      setIsAllApproved(true); // Mark all as approved if there's no next item
    }
  };

  const handlePreviousNews = () => {
    if (currentNewsIndex > 0) {
      const newIndex = currentNewsIndex - 1;
      setCurrentNewsIndex(newIndex);
      form.setFieldsValue(newsData[newIndex]); // Update form with the new news item
    }
  };

  const handleBackClick = () => {
    history(-1); // Go back to the previous page
  };

  // Determine the source based on the data type
  const getSource = () => {
    console.log(data);
    switch (data) {
      case "mint":
        return "LiveMint News";
      case "financial":
        return "Financial Express News";
      case "bs":
        return "Business Standard News";
      case "economics":
        return "Economic Times News";
      case "moneycontrol":
        return "Money Control News";
      // Add more cases as needed
      default:
        return "Unknown Source";
    }
  };

  // If all news items are approved, show "No Data" message with a Back button
  if (isAllApproved) {
    return (
      <Result
        status="success"
        title="All news has been approved!!!"
        extra={
          <Button type="primary" onClick={handleBackClick}>
            Back to Previous Page
          </Button>
        }
      />
    );
  }

  // If there is no news data or we have exhausted the list
  if (newsData.length === 0 || currentNewsIndex >= newsData.length) {
    return (
      <Result
        status="success"
        title="All news has been approved!!!"
        extra={
          <Button type="primary" onClick={handleBackClick}>
            Back to Previous Page
          </Button>
        }
      />
    );
  }

  return (
    <Card className="container">
      <Button
        style={{ float: "left" }}
        type="primary"
        shape="round"
        title="Back "
        icon={<LeftOutlined />}
        onClick={handleBackClick}
      >
        Go Back
      </Button>
      <Title level={3}>
        News Approval {currentNewsIndex + 1}/{newsData.length}
      </Title>
      <Title level={4} className="text-left mt-3">
        Source: {getSource()}
      </Title>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="" name="name">
          <Title level={4} className="text-left mt-3">
            Company Name -{" "}
            {newsData[currentNewsIndex].name ||
              newsData[currentNewsIndex].company}
          </Title>
        </Form.Item>

        <Form.Item
          label="News Title"
          name="title"
          rules={[{ required: true, message: "Title Should not be Empty" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="News Content"
          name="content"
          rules={[{ required: true, message: "Content Should not be Empty" }]}
        >
          <Input.TextArea rows={15} />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ marginRight: "8px" }}
            onClick={handlePreviousNews}
            type="primary"
            danger
          >
            Previous without Publishing
          </Button>
          <Button type="primary" htmlType="submit" size="large">
            Publish News
          </Button>
          <Button
            style={{ marginLeft: "8px" }}
            onClick={handleNextNews}
            type="primary"
            danger
          >
            Next without Publishing
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default NewsApproval;
