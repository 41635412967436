import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

const BondsTradedInCapitalMarket = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { isDark } = useContext(ThemeContext);

  useEffect(() => {
    const fetchAnnouncementData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}/nse-trading-vol`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${token}`, // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Log the response data to the console
        setAnnouncementData(data);
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };

    fetchAnnouncementData();
  }, []);

  return (
    <div
      className='isin-container'
      style={{ backgroundColor: isDark && "gray" }}
    >
      <div className='isin-header'>
        <h1>
          Bonds Traded In Capital Market [Trading Volume]{" "}
          <a
            href='https://www.nseindia.com/market-data/bonds-traded-in-capital-market'
            target='_blank'
          >
            LINK
          </a>{" "}
        </h1>
      </div>
      <table className='announcement-table'>
        <thead>
          <tr>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Symbol
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Coupon Rate
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Credit Rating
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              face_Value{" "}
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              LTP
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Maturity Date
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Percentage Change
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Series
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Bond Type
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Value in Crores
            </th>
            <th
              style={{
                backgroundColor: isDark && "#717171",
                color: isDark && "white",
              }}
            >
              Volume Shares
            </th>
          </tr>
        </thead>
        <tbody>
          {announcementData.map((announcement, index) => (
            <tr key={index}>
              <td style={{ color: isDark && "white" }}>
                {announcement.symbol}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.coupon_Rate}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.credit_Rating}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.face_Value}
              </td>
              <td style={{ color: isDark && "white" }}>{announcement.ltp}</td>
              <td style={{ color: isDark && "white" }}>
                {announcement.maturity_Date}
              </td>{" "}
              <td style={{ color: isDark && "white" }}>
                {announcement.percentage_Change}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.series}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.bond_Type}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.value_Crores}
              </td>
              <td style={{ color: isDark && "white" }}>
                {announcement.volume_Shares}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BondsTradedInCapitalMarket;
