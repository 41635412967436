import { Table, Tag } from "antd";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ThemeContext } from "../../context/ThemeContext";

// Convert timestamp to readable format
const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleString();
};

// Columns for the Ant Design Table
const columns = [
  {
    title: "Order Id",
    dataIndex: "order_id",
    key: "order_id",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount) => `₹ ${(amount / 100).toFixed(2)}`,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (email) => <a>{email}</a>,
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
    render: (contact) => <a>{contact}</a>,
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (timestamp) => formatDate(timestamp),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <Tag color={status === "captured" ? "green" : "red"}>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </Tag>
    ),
  },
];

const PaymentDetails = () => {
  const [paymentData, setPaymentData] = useState([]);
  const baseUrl = process.env.REACT_APP_RAZORPAY_BASE_URL;
  const { isDark } = useContext(ThemeContext);

  const components = {
    table: (props) => (
      <table
        {...props}
        style={{
          backgroundColor: isDark ? "#3f3f3f" : "white",
          color: isDark ? "white" : "black",
          border: isDark && "1px solid gray",
        }}
      >
        {props.children}
      </table>
    ),
    body: {
      row: ({ className, ...restProps }) => (
        <tr
          {...restProps}
          style={{
            backgroundColor: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        />
      ),
      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
    header: {
      row: (props) => (
        <tr
          {...props}
          style={{
            background: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        >
          {props.children}
        </tr>
      ),

      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            fontWeight: "bold",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + "/api/rzp/all/payments", {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization:
              "Bearer " + localStorage.getItem("razorpayAuthToken"),
          },
        });

        // Transform the API response into the desired format
        const transformedData = response.data.map((item, index) => ({
          key: index,
          order_id: item.payment.order_id,
          amount: item.payment.amount,
          created_at: item.payment.created_at,
          status: item.payment.status,
          email: item.payment.email,
          contact: item.payment.contact,
        }));

        setPaymentData(transformedData);
        console.log("Transformed Data:", transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1 className='my-4' style={{ color: isDark && "white" }}>
        Payment Details
      </h1>
      <Table
        columns={columns}
        dataSource={paymentData}
        components={components}
      />
    </div>
  );
};

export default PaymentDetails;
