import { useState, useEffect, useContext } from "react";
import { Tabs, Typography, Table, Row, Col, Button, Card } from "antd";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import { ThemeContext } from "../context/ThemeContext";
import "./RatingStyle.css";
const { TabPane } = Tabs;
const { Title } = Typography;

const Demo = (props) => {
  const location = useLocation();
  // console.log(location.state);
  const history = useNavigate();
  const receivedData = location.state?.data;
  const [name, setName] = useState(
    receivedData && receivedData.name ? receivedData.name : ""
  );
  const [loading, setLoading] = useState(true);
  const [care_rating_data, setCareRating] = useState([]);
  const [icra_rating_data, setIcraRating] = useState([]);
  const [fitch_rating_data, setFitchRating] = useState([]);
  const [crisil_rating_data, setCrisilRating] = useState([]);
  const [india_rating_data, setIndiaRating] = useState([]);
  const [careRational, setCareRational] = useState([]);
  const [icraRational, setIcraRational] = useState([]);
  const [crisilRational, setCrisilRational] = useState([]);
  const [indiaRational, setIndiaRational] = useState([]);
  const [fitchRational, setFitchRational] = useState([]);
  const [tab, setTab] = useState("care_rating");
  const type = "null";
  const { isDark } = useContext(ThemeContext);

  const components = {
    table: (props) => (
      <table
        {...props}
        style={{
          backgroundColor: isDark ? "#3f3f3f" : "white",
          color: isDark ? "white" : "black",
          border: isDark && "1px solid gray",
        }}
      >
        {props.children}
      </table>
    ),
    body: {
      row: ({ className, ...restProps }) => (
        <tr
          {...restProps}
          style={{
            backgroundColor: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        />
      ),
      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
    header: {
      row: (props) => (
        <tr
          {...props}
          style={{
            background: isDark ? "#3f3f3f" : "white",
            color: isDark ? "white" : "black",
          }}
        >
          {props.children}
        </tr>
      ),

      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            fontWeight: "bold",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
  };

  const care_rating_columns = [
    {
      title: "Instruments",
      dataIndex: "instrument",
      key: "instrument",
    },
    {
      title: "Rated Amount ( ₹ in Million )",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Rating",
      dataIndex: "ratings",
      key: "ratings",
    },
  ];
  const icra_rating_columns = [
    {
      title: "Instruments",
      dataIndex: "instrument",
      key: "instrument",
    },
    {
      title: "Rating",
      dataIndex: "ratings",
      key: "ratings",
    },
  ];
  const fitch_rating_column = [
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
  ];
  const crisil_rating_columns = [
    {
      title: "Instrument Category",
      dataIndex: "instrument",
      key: "instrument",
    },

    {
      title: "Rating",
      dataIndex: "ratings",
      key: "ratings",
    },
    {
      title: "Outlook",
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const india_rating_columns = [
    {
      title: "Instrument / Issuer Rating",
      dataIndex: "instrument",
      key: "instrument",
    },
    {
      title: "Amount (in Mln.)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Rating",
      dataIndex: "ratings",
      key: "ratings",
    },
  ];
  const cardData = [
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
    {
      title: "title",
      describtion:
        "jhbcjbbfcjbkjcbjhv njjhvjfvhfvjfv\njvsvfjvjhfhjfvcjhjv\nfbjfbjvf",
    },
  ];
  const baseUrl = process.env.REACT_APP_BASE_URL;
  //For Manually Saving Data
  const saveData = async (key) => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(
        `${baseUrl}/${key}/rating/save`, //   /care/rating/${name} changed to /care/${name}
        { headers }
      );
      // Assuming your API returns a JSON response
      if (response && response.data) {
        console.log("New Data Saved");
        alert("New Data Saved");
        Navigate(0);
      }
    } catch (error) {
      console.error("Error Saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCareData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };

      const response = await axios.get(
        `${baseUrl}/care/rating/${name}`, //   /care/rating/${name} changed to /care/${name}
        { headers }
      );
      // Assuming your API returns a JSON response
      if (response && response.data && response.data[0] != undefined) {
        console.log("Done Care Data " + response.data);
        console.log("CARE " + response.data[0].data[1].instrument); //Extracting full object containing id,name ,url , data
        setCareRating(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/care/rational/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response
      if (response && response.data && response.data != undefined) {
        setCareRational(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCrisilData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };

      const response = await axios.get(`${baseUrl}/crisil/rating/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response

      if (response && response.data && response.data != undefined) {
        console.log("Crisil Data Name" + response.data[0].data[1].name);
        setCrisilRating(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/crisil/rational/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response
      if (response && response.data) {
        console.log("Length = " + response.data.length);
        setCrisilRational(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchFitchData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/fitch/rating/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response

      if (response && response.data && response.data[0] != undefined) {
        console.log("Fitch Data here" + response.data);
        setFitchRating(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/fitch/rational/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response
      if (response && response.data) {
        console.log("Fitch Rational Data here " + response.data);
        console.log("Length = " + response.data.length);
        setFitchRational(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchIcraData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/icra/rating/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response
      if (response && response.data && response.data[0] != undefined) {
        console.log("ICRA Data here" + response.data[0]);
        setIcraRating(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/icra/rational/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response
      if (response && response.data) {
        setIcraRational(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchIndiaData = async () => {
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/india/rating/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response

      if (response && response.data && response.data != undefined) {
        console.log("INDIA Data here " + response.data[0].data[0].amount);
        setIndiaRating(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")} `,
      };
      const response = await axios.get(`${baseUrl}/india/rational/${name}`, {
        headers,
      });

      // Assuming your API returns a JSON response
      if (response && response.data) {
        console.log("India Rat 0" + response.data);
        console.log("India Rat 1" + response.data[0].data[0]);
        console.log("India Rat 2" + response.data[0].data[1]);
        console.log("India Rat 3" + response.data[0].data[2]);
        setIndiaRational(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareData();
    fetchCrisilData();
    fetchFitchData();
    fetchIcraData();
    fetchIndiaData();
  }, []);
  function callback(key) {
    console.log(key);
  }
  return (
    <div>
      <div style={{ display: "flex" }}>
        <Title level={2} style={{ color: isDark && "white" }}>
          {name}
        </Title>
      </div>

      <Tabs onChange={callback} className={isDark && "custom-tabs"}>
        <TabPane tab='Care Ratings' key='care' style={{ color: "red" }}>
          <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Col span={8}>
              <Title level={4} style={{ color: isDark && "white" }}>
                Timestamp: {care_rating_data._id && care_rating_data._id.date}
                {/* Timestamp: {care_rating.id && care_rating.timestamp} */}
              </Title>
            </Col>
            <Col span={16}>
              <Title level={4} style={{ color: isDark && "white" }}>
                URL :{" "}
                <a
                  href={care_rating_data && care_rating_data.url}
                  target='_blank'
                >
                  {" "}
                  {care_rating_data && care_rating_data.url}{" "}
                </a>
              </Title>

              <Title level={4}>
                <Button
                  onClick={() => saveData("care")}
                  style={{
                    backgroundColor: isDark && "gray",
                    border: isDark && "none",
                    color: isDark && "white",
                  }}
                >
                  Get Latest Data
                </Button>
              </Title>
            </Col>
          </Row>

          <Row>
            <Table
              dataSource={care_rating_data.data}
              columns={care_rating_columns}
              pagination={false}
              scroll={{ y: 350 }}
              loading={loading}
              components={components}
            />
          </Row>
          {careRational && careRational.length !== 0 && (
            <>
              <div style={{ textAlign: "center" }}>
                <Title style={{ marginTop: "50px" }}>Rational</Title>
              </div>
              <div style={{ background: "#ECECEC", padding: "30px" }}>
                <Row gutter={16}>
                  {careRational &&
                    careRational.length != 0 &&
                    careRational.map((data) => {
                      return (
                        <Col
                          span={8}
                          style={{ padding: "5px", borderRadius: "50px" }}
                        >
                          <Card
                            title={data && data.name ? data.name : "--"}
                            bordered={true}
                          >
                            <strong>
                              {data && data.timestamp ? data.timestamp : "--"}
                            </strong>
                            <p>
                              {data && data.data && data.data.length > 100
                                ? data.data.slice(0, 100)
                                : "--"}
                              <strong
                                onClick={() =>
                                  history("/d/rational", {
                                    state: { data: data },
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "#627df5",
                                  textDecoration: "underline",
                                }}
                              >
                                ...read more
                              </strong>
                            </p>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tab='Crisil Ratings' key='crisil'>
          <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Col span={8}>
              <Title level={4}>
                Timestamp:{" "}
                {crisil_rating_data._id && crisil_rating_data._id.date}
              </Title>
            </Col>
            <Col span={16}>
              <Title level={4}>
                URL :{" "}
                <a
                  href={crisil_rating_data && crisil_rating_data.url}
                  target='_blank'
                >
                  {" "}
                  {crisil_rating_data && crisil_rating_data.url}{" "}
                </a>
              </Title>
            </Col>
          </Row>

          <Row>
            <Table
              dataSource={crisil_rating_data.data}
              columns={crisil_rating_columns}
              pagination={false}
              scroll={{ y: 350 }}
              components={components}
            />

            {crisilRational && crisilRational.length !== 0 && (
              <>
                <div style={{ textAlign: "center" }}>
                  <Title style={{ marginTop: "50px" }}>Rational</Title>
                </div>
                <div style={{ background: "#ECECEC", padding: "30px" }}>
                  <Row gutter={16}>
                    {crisilRational &&
                      crisilRational.length != 0 &&
                      crisilRational.map((data) => {
                        return (
                          <Col
                            span={8}
                            style={{ padding: "5px", borderRadius: "50px" }}
                          >
                            <Card
                              title={data && data.name ? data.name : "--"}
                              bordered={true}
                            >
                              <strong>
                                {data && data.timestamp ? data.timestamp : "--"}
                              </strong>
                              <p>
                                {data && data.data && data.data.length > 100
                                  ? data.data.slice(0, 100)
                                  : "--"}
                                <strong
                                  onClick={() =>
                                    history("/d/rational", {
                                      state: { data: data },
                                    })
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: "#627df5",
                                  }}
                                >
                                  ...read more
                                </strong>
                              </p>
                            </Card>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </>
            )}
          </Row>
        </TabPane>
        <TabPane tab='Fitch Ratings' key='fitch'>
          <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Col span={8}>
              <Title level={4}>
                Timestamp: {fitch_rating_data._id && fitch_rating_data._id.date}
              </Title>
            </Col>
            <Col span={16}>
              <Title level={4}>
                URL :{" "}
                <a
                  href={fitch_rating_data && fitch_rating_data.url}
                  target='_blank'
                >
                  {" "}
                  {fitch_rating_data && fitch_rating_data.url}{" "}
                </a>
              </Title>
            </Col>
          </Row>

          <Row>
            <Table
              dataSource={fitch_rating_data.data}
              columns={fitch_rating_column}
              pagination={false}
              scroll={{ y: 350 }}
              components={components}
            />
          </Row>
          {fitchRational && fitchRational.length !== 0 && (
            <>
              <div style={{ textAlign: "center" }}>
                <Title style={{ marginTop: "50px" }}>Rational</Title>
              </div>
              <div style={{ background: "#ECECEC", padding: "30px" }}>
                <Row gutter={16}>
                  {fitchRational &&
                    fitchRational.length != 0 &&
                    fitchRational.map((data) => {
                      return (
                        <Col
                          span={8}
                          style={{ padding: "5px", borderRadius: "50px" }}
                        >
                          <Card
                            title={data && data.name ? data.name : "--"}
                            bordered={true}
                          >
                            <strong>
                              {data && data.timestamp ? data.timestamp : "--"}
                            </strong>
                            <p>
                              {data && data.data && data.data.length > 100
                                ? data.data.slice(0, 100)
                                : "--"}
                              <strong
                                onClick={() =>
                                  history("/d/rational", {
                                    state: { data: data, type: "fitch" },
                                  })
                                }
                                style={{ cursor: "pointer", color: "#627df5" }}
                              >
                                ...read more
                              </strong>
                            </p>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tab='ICRA Ratings' key='icra'>
          <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Col span={8}>
              {/* <Title level={4}>
                Timestamp: { icra_rating_data._id && icra_rating_data._id.date}
              </Title> */}
              <Title level={4}>
                {icra_rating_data ? (
                  <span>
                    Timestamp:{" "}
                    {icra_rating_data._id && icra_rating_data._id.date}
                  </span>
                ) : null}
              </Title>
            </Col>
            <Col span={16}>
              <Title level={4}>
                URL :{" "}
                <a
                  href={icra_rating_data && icra_rating_data.url}
                  target='_blank'
                >
                  {" "}
                  {icra_rating_data && icra_rating_data.url}{" "}
                </a>
              </Title>
            </Col>
          </Row>

          <Row>
            <Table
              dataSource={icra_rating_data.data}
              columns={icra_rating_columns}
              components={components}
              pagination={false}
              scroll={{ y: 350 }}
              footer={() => "Note : withdrawn ratings are not displayed above"}
            />
          </Row>
          {icraRational && icraRational.length !== 0 && (
            <>
              <div style={{ textAlign: "center" }}>
                <Title style={{ marginTop: "50px" }}>Rational</Title>
              </div>
              <div style={{ background: "#ECECEC", padding: "30px" }}>
                <Row gutter={16}>
                  {icraRational &&
                    icraRational.length != 0 &&
                    icraRational.map((data) => {
                      return (
                        <Col
                          span={8}
                          style={{ padding: "5px", borderRadius: "50px" }}
                        >
                          <Card
                            title={data && data.name ? data.name : "--"}
                            bordered={true}
                          >
                            <strong>
                              {data && data.timestamp ? data.timestamp : "--"}
                            </strong>
                            <p>
                              {data && data.data && data.data.length > 100
                                ? data.data.slice(0, 100)
                                : "--"}
                              <strong
                                onClick={() =>
                                  history("/d/rational", {
                                    state: { data: data },
                                  })
                                }
                                style={{ cursor: "pointer", color: "#627df5" }}
                              >
                                ...read more
                              </strong>
                            </p>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </>
          )}
        </TabPane>
        <TabPane tab='India Ratings' key='india'>
          <Row style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Col span={8}>
              <Title level={4}>
                Timestamp: {india_rating_data._id && india_rating_data._id.date}
              </Title>
            </Col>
            <Col span={16}>
              <Title level={4}>
                URL :{" "}
                <a
                  href={india_rating_data && india_rating_data.url}
                  target='_blank'
                >
                  {" "}
                  {india_rating_data && india_rating_data.url}{" "}
                </a>
              </Title>
            </Col>
          </Row>

          <Row>
            <Table
              components={components}
              dataSource={india_rating_data.data}
              columns={india_rating_columns}
              pagination={false}
              scroll={{ y: 350 }}
            />
          </Row>

          {indiaRational && indiaRational.length !== 0 && (
            <>
              <div style={{ textAlign: "center" }}>
                <Title style={{ marginTop: "50px" }}>Rational</Title>
              </div>
              <div style={{ background: "#ECECEC", padding: "30px" }}>
                <Row gutter={16}>
                  {indiaRational &&
                    indiaRational.length != 0 &&
                    indiaRational.map((data) => {
                      return (
                        <Col
                          span={8}
                          style={{ padding: "5px", borderRadius: "50px" }}
                        >
                          <Card
                            title={data && data.name ? data.name : "--"}
                            bordered={true}
                          >
                            <strong>
                              {data && data.timestamp ? data.timestamp : "--"}
                            </strong>
                            <p>
                              {data && data.data && data.data.length > 100
                                ? data.data.slice(0, 100)
                                : // data.data.slice(0, 100)
                                  "--"}
                              <strong
                                onClick={() =>
                                  history("/d/rational", {
                                    state: { data: data, type: "india" },
                                  })
                                }
                                style={{ cursor: "pointer", color: "#627df5" }}
                              >
                                ...read more
                              </strong>
                            </p>
                          </Card>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Demo;
