import React, { useContext } from "react";
import "../shared/staticStyles.css";
import { Link, useNavigate } from "react-router-dom";
import domainImage from "../../assets/domainConcepts.png";
import CardsSlider from "../CardsSlider";
import { ThemeContext } from "../../context/ThemeContext";

const DomainConcepts = () => {
  const navigate = useNavigate();

  const { isDark } = useContext(ThemeContext);

  const items = [
    {
      name: "Must know Category",
      info: "Essential insights and foundational knowledge required for understanding key concepts within our domain.",
      navigate: "mustknow",
    },
    {
      name: "Great to know Category",
      info: "Additional information and insights that enhance your understanding and provide deeper insights into our industry.",
      navigate: "greatoknow",
    },
    {
      name: "Good to know Category",
      info: "Supplementary knowledge and useful tips that add value to your understanding of our field, though not essential for foundationalutton",
      navigate: "goodtoknow",
    },
    {
      name: "Miscellaneous",
      info: "Useful tips that add value to your understanding of our field, though not essential for foundational comprehension.",
      navigate: "",
    },
  ];

  return (
    <div className='Start' style={{ color: isDark && "white" }}>
      {/* <Sidebar /> */}
      <div className='main-content'>
        <div>
          <h1 style={{ fontWeight: "bolder" }}>Domain Concepts</h1>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: ".5fr .5fr",
              alignItems: "center",
            }}
          >
            <p style={{ marginTop: "1rem", width: "50vw" }}>
              Domain Concepts is your gateway to in-depth exploration of
              fundamental principles and theories that underpin our industry.
              Delve into detailed explanations and insightful analyses covering
              a diverse range of topics within our domain. Whether you're
              seeking foundational knowledge or advanced insights, this section
              offers comprehensive resources to satisfy your curiosity and
              expand your understanding of our field.
              <br />
              <br />
              <b>
                We have identified and broken it down into three parts for
                further simplification: Must Know, Great To Know, and Good To
                Know.
              </b>
            </p>
            <img src={domainImage} alt='image' className='dImg' />
          </div>
        </div>

        <CardsSlider cardsInfo={items} navigate={navigate} />
      </div>
    </div>
  );
};

export default DomainConcepts;
