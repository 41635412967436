// src/components/OurValues.js
import React, { useContext } from "react";
import { Container, Row, Col, Card, Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "../App.css";
import { Divider } from "antd";
import App from "../App";
import { ThemeContext } from "../context/ThemeContext";
const OurValues = () => {
  const navigate = useNavigate();

  const { isDark } = useContext(ThemeContext);

  return (
    <div>
      {/* <Navbar bg="primary" variant="dark" expand="lg" style={{ color: '#007bff' }}>
        <Container className="a">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home" style={{ color: 'white' }}>Home</Nav.Link>
              <Nav.Link href="#values" style={{ color: 'white' }}>Values</Nav.Link>
              <Nav.Link href="#leadership" style={{ color: 'white' }}>Leadership</Nav.Link>
              <Nav.Link href="#knowledgehub" style={{ color: 'white' }}>Knowledge Hub</Nav.Link>
              <Nav.Link href="#framework" style={{ color: 'white' }}>Framework</Nav.Link>
              <Nav.Link href="#testimonials" style={{ color: 'white' }}>Testimonials</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Button variant="light">Register/Signup</Button>
        </Container>
      </Navbar> */}

      <div className='text-center' style={{ color: isDark && "white" }}>
        <div
          className={`h-screen px-8	center-component ${
            isDark ? 'bg-["#717171"]' : "bg-blue-100"
          }	`}
          style={{ background: isDark && "#8b8b8b" }}
        >
          <div>
            <Divider
              className={`${
                isDark ? "text-white" : "tc-blue"
              } fs-1 fw-bold my-4`}
            >
              Pledge ( प्रतिज्ञा )
            </Divider>
            <hr
              className={`w-25 mx-auto ${isDark ? "text-white" : "tc-blue"}`}
            />
            <Row className='fw-medium text-xl text-start'>
              <Col md={6}>
                <p>
                  We at SimplyBonds commit our services and competencies to
                  serve retail investors. We appreciate and recognize that
                  retail savings are not only a key part of achieving financial
                  objectives of life but also important for financing economic
                  activities. Hence, to protect retail interest will always
                  remain our prime concern. We, along with all our stakeholders,
                  pledge committed support and offer our dedicated services to
                  provide required solutions to retail investors. The well-being
                  of retail and their prosperity will remain at the core of our
                  activities and we consider to serve retail interest our prime
                  duty and the sustainable goal of our business at SimplyBonds.{" "}
                </p>
              </Col>
              <Col md={6}>
                <p>
                  सरल निवेश में हम खुदरा निवेशकों की सेवा के लिए अपनी सेवाओं और
                  दक्षताओं के लिए प्रतिबद्ध हैं। हम सराहना करते हैं और मानते हैं
                  कि खुदरा बचत न केवल जीवन के वित्तीय उद्देश्यों को प्राप्त करने
                  का महत्वपूर्ण हिस्सा है बल्कि आर्थिक गतिविधियों के वित्तपोषण
                  के लिए भी महत्वपूर्ण है। इसलिए, खुदरा हितों की रक्षा करना
                  हमेशा हमारी प्रमुख चिंता रहेगी। हम अपने सभी हितधारकों के साथ
                  प्रतिबद्ध समर्थन का संकल्प लेते हैं और खुदरा निवेशकों को
                  आवश्यक समाधान प्रदान करने के लिए अपनी समर्पित सेवाएं प्रदान
                  करते हैं। खुदरा क्षेत्र की भलाई और उनकी समृद्धि हमारी
                  गतिविधियों के मूल में रहेगी और हम सरल निवेश में खुदरा हितों की
                  सेवा को अपना प्रमुख कर्तव्य और अपने व्यवसाय का स्थायी लक्ष्य
                  मानते हैं।
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <div className='h-screen mx-8	center-component'>
          <div>
            <Divider
              className={`${
                isDark ? "text-white" : "tc-blue"
              } fs-1 fw-bold my-4`}
            >
              Mission ( ध्येय )
            </Divider>
            <hr
              className={`w-25 mx-auto ${isDark ? "text-white" : "tc-blue"}`}
            />
            <Row className='fw-medium text-xl text-start'>
              <Col md={6} className='text-start'>
                <p>
                  Our Mission is to offer a credible ecosystem primarily to
                  retail investors, providing fixed income investment solutions
                  with a clear focus on safety, liquidity, and regular cash
                  flow.
                </p>
              </Col>
              <Col md={6}>
                <p>
                  हमारा मिशन मुख्य रूप से खुदरा निवेशकों को सुरक्षा, तरलता और
                  नियमित नकदी प्रवाह पर स्पष्ट ध्यान देने के साथ निश्चित आय
                  निवेश समाधान प्रदान करने के लिए विश्वसनीय पारिस्थितिकी तंत्र
                  की पेशकश करना है।{" "}
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className={`h-screen px-8	center-component ${
            isDark ? 'bg-["#8b8b8b"]' : "bg-blue-100"
          }	`}
          style={{ background: isDark && "#8b8b8b" }}
        >
          <Row>
            <Divider
              className={`fs-1 fw-bold my-4 ${
                isDark ? "text-white" : "tc-blue"
              }`}
            >
              Principles ( तत्व )
            </Divider>
            <Col md={4}>
              <Card
                style={{
                  color: isDark && "white",
                  backgroundColor: isDark && "#555555",
                }}
              >
                <Card.Body>
                  <img
                    src='https://663b46d3917836ef789d01d8--symphonious-stroopwafel-7d7f81.netlify.app/assets/images/svg/principle_icon.svg'
                    alt='Ecosystem Icon'
                    width='60'
                    className='my-4'
                  />
                  <h4
                    className='text-4xl	component-title fw-bold'
                    style={{ color: isDark && "white" }}
                  >
                    Ecosystem
                  </h4>
                  <p className='text-xl'>
                    Creating sustainable mode for mobilizing long-term retail
                    savings.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                style={{
                  backgroundColor: isDark && "#555555",
                  color: isDark && "white",
                }}
              >
                <Card.Body>
                  <img
                    src='https://663b46d3917836ef789d01d8--symphonious-stroopwafel-7d7f81.netlify.app/assets/images/svg/principle_icon.svg'
                    alt='Sustainability Icon'
                    width='60'
                    className='my-4'
                  />
                  <h4
                    className='text-4xl	component-title fw-bold'
                    style={{ color: isDark && "white" }}
                  >
                    Sustainability
                  </h4>
                  <p className='text-xl'>
                    Ensuring sustainable mobilization of retail savings.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                style={{
                  backgroundColor: isDark && "#555555",
                  color: isDark && "white",
                }}
              >
                <Card.Body>
                  <img
                    src='https://663b46d3917836ef789d01d8--symphonious-stroopwafel-7d7f81.netlify.app/assets/images/svg/principle_icon.svg'
                    alt='Governance Icon'
                    width='60'
                    className='my-4'
                  />
                  <h4
                    className='text-4xl	component-title fw-bold'
                    style={{ color: isDark && "white" }}
                  >
                    Governance
                  </h4>
                  <p className='text-xl'>
                    Ensuring robust governance framework.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>{" "}
        <div className='h-screen mx-8	center-component '>
          <Row>
            <Divider
              className={`fs-1 fw-bold my-4 ${
                isDark ? "text-white" : "tc-blue"
              }`}
            >
              Practices ( आचरण )
            </Divider>
            <Col md={4}>
              <Card
                style={{
                  backgroundColor: isDark && "#555555",
                  color: isDark && "white",
                }}
              >
                <Card.Body>
                  <img
                    src='https://663b46d3917836ef789d01d8--symphonious-stroopwafel-7d7f81.netlify.app/assets/images/svg/principle_icon.svg'
                    alt='Ecosystem Icon'
                    width='60'
                    className='my-4'
                  />
                  <h4
                    className='text-4xl	component-title fw-bold'
                    style={{ color: isDark && "white" }}
                  >
                    Ease of Execution
                  </h4>
                  <p className='text-xl'>
                    Providing a seamless experience for operational activitites.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                style={{
                  backgroundColor: isDark && "#555555",
                  color: isDark && "white",
                }}
              >
                <Card.Body>
                  <img
                    src='https://663b46d3917836ef789d01d8--symphonious-stroopwafel-7d7f81.netlify.app/assets/images/svg/principle_icon.svg'
                    alt='Sustainability Icon'
                    width='60'
                    className='my-4'
                  />
                  <h4
                    className='text-4xl	component-title fw-bold'
                    style={{ color: isDark && "white" }}
                  >
                    Transparency
                  </h4>
                  <p className='text-xl'>
                    Transparency in operation like none other.
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                style={{
                  backgroundColor: isDark && "#555555",
                  color: isDark && "white",
                }}
              >
                <Card.Body>
                  <img
                    src='https://663b46d3917836ef789d01d8--symphonious-stroopwafel-7d7f81.netlify.app/assets/images/svg/principle_icon.svg'
                    alt='Governance Icon'
                    width='60'
                    className='my-4'
                  />
                  <h4
                    className='text-4xl	component-title fw-bold'
                    style={{ color: isDark && "white" }}
                  >
                    Evolving
                  </h4>
                  <p className='text-lg'>
                    Evolving practices to provide solutions under dynamic market
                    conditions.
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
