import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState } from "react";

const RBISavingBonds = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [rbiSavingBonds, setRBISavingBonds] = useState([]);
  useEffect(() => {
    const fetchRBISavingBonds = async () => {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        };
        const response = await axios.get(baseUrl + "/rbi/sb", {
          headers,
        });
        console.log(response.data);
        setRBISavingBonds(response.data);
      } catch (error) {
        console.error("Error fetching RBI Gov Securities data:", error);
      }
    };
    fetchRBISavingBonds();
  }, []);
  return (
    <div className="container">
      <h1 className="my-5">Reserve Bank of India-Saving Bonds</h1>
      <div>
        {rbiSavingBonds.map((bond, index) => {
          return (
            <>
              {bond.key === "title" && <Title level={3}>{bond.value}</Title>}
              {bond.key === "description" && (
                <div className="my-5 text-left">{bond.value}</div>
              )}
              {bond.key != "title" &&
                bond.key != "additional_details" &&
                bond.key != "description" && (
                  <Row className="text-left	my-4">
                    <Col span={8}>{bond.key}</Col>
                    <Col span={16}>{bond.value}</Col>
                  </Row>
                )}
            </>
          );
        })}
      </div>
      <div>
        {rbiSavingBonds.map((bond, index) => {
          // Check the bond's key and render accordingly

          if (bond.key === "additional_details") {
            {
              console.log(bond.value);
            }
            return (
              <div className="text-left my-5">
                <div>{bond.value.substring(0, 94)}</div>
                <div>{bond.value.substring(94, 132)}</div>
                <div>{bond.value.substring(132, 163)}</div>
                <div>{bond.value.substring(163, 173)}</div>
                <div>{bond.value.substring(173, 220)}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default RBISavingBonds;
