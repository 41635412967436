import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

function Module({ data, colors }) {
  const { id } = useParams();
  const { isDark } = useContext(ThemeContext);

  return (
    <div
      className='modulePage'
      style={{
        color: isDark && "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: ".5rem",
          fontWeight: "bolder",
          fontSize: "5rem",
        }}
      >
        {parseInt(id)}
        <span
          className='line1'
          style={{
            backgroundColor: isDark ? "white" : `${colors[id - 1]}`,
          }}
        ></span>
      </div>
      <p style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
        {data[id - 1]?.subheaders}
      </p>
      <p>{data[id - 1]?.content}</p>
    </div>
  );
}

export default Module;
