import { ArrowRightOutlined, LeftOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Table } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminApprovalComponent = () => {
  const history = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [lmCount, setLmCount] = useState(0);
  const [bsCount, setBsCount] = useState(0);
  const [feCount, setFeCount] = useState(0);
  const [etCount, setEtCount] = useState(0);
  const [mcCount, setMcCount] = useState(0);

  const dataColumns = [
    {
      title: "Name",
      width: 500,
      align: "center",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "action",
      render: (text, data) => (
        <Badge count={data.count}>
          <Button
            type="primary"
            onClick={() => action(text)}
            icon={<ArrowRightOutlined />}
            size="large"
          >
            Go to News Approval Page
          </Button>
        </Badge>
      ),
    },
  ];

  const dataSource = [
    {
      key: "0",
      title: "Approve Mint News",
      name: "mint",
      count: lmCount,
    },
    {
      key: "1",
      title: "Approve Business Standard News",
      name: "bs",
      count: bsCount,
    },
    {
      key: "2",
      title: "Approve Financial Express News",
      name: "financial",
      count: feCount,
    },
    {
      key: "3",
      title: "Approve Economic Times News",
      name: "economics",
      count: etCount,
    },
    {
      key: "4",
      title: "Approve Money Control News",
      name: "moneycontrol",
      count: mcCount,
    },
  ];
  const action = (text) => {
    history("/admin/approval/news", {
      state: { data: text },
    });
  };
  useEffect(() => {
    fetchLatestNewCount();
  }, []);
  const fetchLatestNewCount = async () => {
    console.log("fetching latest news count");
    try {
      const headers = {
        "ngrok-skip-browser-warning": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      };
      const response = await axios.get(`${baseUrl}/news/mint/count`, {
        headers,
      });

      if (response && response.data) {
        setBsCount(response.data.bsCount);
        setFeCount(response.data.feCount);
        setEtCount(response.data.etCount);
        setMcCount(response.data.mcCount);
        setLmCount(response.data.lmCount);
      }
    } catch (error) {
      console.error("Error Saving data:", error);
      alert("Error Occurred");
    } finally {
    }
  };

  return (
    <div>
      <Card className="container">
        <Button
          style={{ margin: "5px", float: "left" }}
          type="primary"
          shape="round"
          title="Back "
          icon={<LeftOutlined />}
          onClick={() => history(-1)}
        >
          Go Back
        </Button>
        <Table
          columns={dataColumns}
          dataSource={dataSource}
          title={() => <Title level={3}>News Approval Dashboard</Title>}
          pagination={false}
          rowKey="name"
        />
      </Card>
    </div>
  );
};

export default AdminApprovalComponent;
