import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Routers from "./Router/router";
import "./styles/font.css";
import { ThemeContext, ThemeProvider } from "./context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
    <Router>
      <Routers />
    </Router>
  </ThemeProvider>
);
