import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import "./testimonials.css";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
const Testimonials = () => {
  const navigate = useNavigate();
  const { isDark } = useContext(ThemeContext);

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: isDark ? "#717171" : "#F6FAFF",
        padding: "1rem",
        color: isDark && "white",
      }}
    >
      <div>
        <div class=''>
          <div class='fs-1 fw-bold text-center'>
            <div>Trusted & backed by India’s</div>
            <div>top financial leaders and</div>
            <div>educators</div>
            <div class='fs-6 mt-3'>
              Be it experienced fintech founders or your favorite finance
              influencers, they all
            </div>
            <div class='fs-6'>trust us.</div>
          </div>
        </div>

        <div
          class='slider'
          style={{ backgroundColor: isDark ? "#717171" : "#F6FAFF" }}
        >
          <div class='slide-track'>
            <div class='container d-flex gap-3 my-5'>
              {Array.from({ length: 10 }).map((_, index) => (
                <div
                  className='cards p-3'
                  key={index}
                  style={{
                    height: "fit-content",
                    boxShadow: isDark && "12px 17px 51px black",
                  }}
                >
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className=''>
                        <Avatar
                          size={64}
                          src='https://i.pravatar.cc/300?img=56'
                        />{" "}
                      </div>
                    </div>
                    <div className='col-md-8'>
                      <div className='text-start p-3'>
                        <div className='text-lg'> John Doe</div>
                        <div>Xyz, CEO</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
