import React, { useContext, useEffect, useState } from "react";
import "../shared/staticStyles.css";
import { ThemeContext } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const colors = [
  "Green",
  "#368fd3",
  "DarkGoldenRod",
  "RebeccaPurple",
  "Pink",
  "ForestGreen",
  "RoyalBlue",
  "GoldenRod",
  "MediumPurple",
  "LightPink",
  "SeaGreen",
  "SkyBlue",
  "SaddleBrown",
  "SlateBlue",
  "HotPink",
  "LimeGreen",
  "DodgerBlue",
  "Peru",
  "BlueViolet",
  "DeepPink",
  "OliveDrab",
  "SteelBlue",
  "Khaki",
  "DarkOrchid",
  "PaleVioletRed",
  "SpringGreen",
  "CornflowerBlue",
  "DarkKhaki",
  "Orchid",
  "LightCoral",
];

const topics = [
  {
    subheaders: "Foreign Currency Bonds",
    content:
      "Foreign currency bonds are debt securities issued by a domestic entity but denominated in a currency other than the issuer's domestic currency. These bonds allow issuers to access international capital markets and diversify their sources of funding.Example: A Chinese corporation issues bonds denominated in US dollars to raise capital from investors outside of China. The bondholders receive interest payments and principal repayment in US dollars.",
  },
  {
    subheaders: "Masala Bonds",
    content:
      "Masala bonds are Indian rupee-denominated bonds issued by non-Indian entities in the international market. These bonds are used to raise funds from foreign investors without exposing them to currency risk, as the principal and interest payments are made in Indian rupees. Example: An Indian corporation issues Masala bonds in London to raise funds for its expansion projects. Foreign investors purchase these bonds and receive interest payments and principal repayment in Indian rupees.",
  },
  {
    subheaders: "Municipal Bonds",
    content:
      "Municipal bonds, also known as munis, are debt securities issued by state or local governments to finance public infrastructure projects, such as schools, roads, or utilities. They offer tax-exempt interest income to investors and are backed by the issuer's taxing authority. Example: A city government in the United States issues municipal bonds to finance the construction of a new public transportation system. Investors who purchase these bonds receive interest payments that are exempt from federal income tax.",
  },
  {
    subheaders: "Sovereign Bonds",
    content:
      "Sovereign bonds are debt securities issued by national governments to raise funds for government expenditures, such as infrastructure projects, social programs, or debt refinancing. They are considered low-risk investments because they are backed by the full faith and credit of the issuing government.Example: The US Treasury issues Treasury bonds to finance government operations and pay off existing debt. These bonds are considered among the safest investments in the world due to the creditworthiness of the US government.",
  },
  {
    subheaders: "Sub-sovereign Bonds",
    content:
      "Sub-sovereign bonds are debt securities issued by regional or local governments within a sovereign nation. They are used to finance regional development projects and are typically backed by the revenue-generating capacity of the issuing entity.Example: A state government in India issues sub-sovereign bonds to finance infrastructure projects within the state, such as the construction of highways or bridges. Investors who purchase these bonds receive interest payments from the state government.",
  },
  {
    subheaders: "PSU Bonds",
    content:
      "PSU bonds are debt securities issued by public sector undertakings (PSUs) in India. These bonds are issued by government-owned companies to raise funds for their operations, expansion, or capital expenditure projects.Example: A state-owned oil company in India issues PSU bonds to finance the construction of new refineries. Investors who purchase these bonds receive interest payments from the company and repayment of the principal amount at maturity.",
  },
  {
    subheaders: "Private Sector Bonds",
    content:
      "Private sector bonds are debt securities issued by privately owned companies to raise funds for various purposes, such as working capital, expansion, or acquisitions. These bonds are not backed by the government and are subject to credit risk.Example: A multinational corporation issues corporate bonds to finance the acquisition of a competitor. Investors who purchase these bonds receive interest payments from the company and repayment of the principal amount at maturity.",
  },
  {
    subheaders: "SLR Securities",
    content:
      "SLR securities, or Statutory Liquidity Ratio securities, are specific types of government securities that banks in India are required to hold as part of their statutory liquidity ratio obligations. These securities serve as a reserve to ensure liquidity and stability in the banking system.Example: Indian banks purchase government bonds issued by the Reserve Bank of India (RBI) to fulfill their SLR requirements. These bonds provide a safe and liquid investment that helps banks maintain their liquidity position.",
  },
  {
    subheaders: "State Government Guaranteed Bonds",
    content:
      "State government guaranteed bonds are debt securities issued by private or public entities within a state but guaranteed by the state government. The guarantee provides assurance to investors regarding the timely payment of interest and principal.Example: A municipal corporation issues bonds to finance the construction of a new water treatment plant, with the bonds guaranteed by the state government. Investors who purchase these bonds receive interest payments and principal repayment guaranteed by the state.",
  },
  {
    subheaders: "Debt Market Mutual Fund",
    content:
      "Debt market mutual funds are investment funds that primarily invest in fixed-income securities, such as bonds, treasury bills, and money market instruments. These funds provide investors with exposure to the debt market while offering diversification and professional management.Example: An investor purchases units of a debt market mutual fund, which then invests in a diversified portfolio of bonds issued by governments, corporations, and other entities. The mutual fund earns interest income from these investments, which is passed on to the investors in the form of dividends or capital gains.",
  },
  {
    subheaders: "Hybrids",
    content:
      "Hybrids are financial instruments that combine features of both debt and equity securities. They typically have characteristics of debt, such as regular interest payments and a fixed maturity date, as well as features of equity, such as the potential for conversion into shares or participation in profit sharing. Example: Convertible bonds are a common type of hybrid security, offering bondholders the option to convert their bonds into shares of stock at a predetermined conversion ratio.",
  },
  {
    subheaders: "Structured Obligation",
    content:
      "Structured obligations are debt securities with customized terms and conditions designed to meet specific investor needs or to align with the issuer's financial objectives. These securities often incorporate complex structures, such as credit enhancements or collateral arrangements, to mitigate risk. Example: Collateralized debt obligations (CDOs) are structured obligations that pool together various types of debt securities, such as bonds or loans, and redistribute the cash flows to investors based on their investment preferences and risk appetite.",
  },
  {
    subheaders: "Future Flow Securitisation",
    content:
      "Future flow securitization is a type of asset-backed security where the cash flows generated by future revenue streams, such as export receivables or future sales, are used as collateral to secure bonds or notes issued to investors. It allows companies to monetize future income streams and raise capital upfront. Example: An airline company may securitize its future ticket sales receivables by issuing bonds backed by the expected revenue from ticket sales over a specified period. Investors receive interest payments and principal repayment from the cash flows generated by ticket sales.",
  },
  {
    subheaders: "Mortgage Backed Securities (MBS)",
    content:
      "Mortgage-backed securities are debt securities backed by a pool of mortgage loans. These securities are divided into tranches based on the credit quality of the underlying mortgages and the priority of repayment. Investors receive interest payments and principal repayment based on the cash flows from the underlying mortgage payments. Example: A financial institution packages a pool of mortgage loans into a mortgage-backed security and sells shares of the security to investors. Investors receive regular interest payments and principal repayment based on the performance of the underlying mortgages.",
  },
  {
    subheaders: "Asset Backed Securities (ABS)",
    content:
      "Asset-backed securities are debt securities backed by a pool of financial assets, such as auto loans, credit card receivables, or student loans. These securities are structured similarly to MBS but are backed by a broader range of assets. Example: A bank securitizes its portfolio of auto loans by issuing asset-backed securities backed by the future cash flows from the loan repayments. Investors receive interest payments and principal repayment based on the performance of the underlying auto loans.",
  },
  {
    subheaders: "REIT (Real Estate Investment Trust)",
    content:
      "A real estate investment trust is a company that owns, operates, or finances income-generating real estate properties. REITs allow investors to invest in real estate assets without directly owning or managing the properties. They are required to distribute a significant portion of their taxable income to shareholders in the form of dividends. Example: A REIT may own and manage a portfolio of commercial properties, such as office buildings, shopping malls, or apartment complexes. Investors can purchase shares of the REIT and receive dividends based on the rental income generated by the properties.",
  },
  {
    subheaders: "INVIT (Infrastructure Investment Trust)",
    content:
      "An infrastructure investment trust is a type of investment vehicle that invests in revenue-generating infrastructure projects, such as roads, highways, ports, or power plants. INVITs allow investors to participate in infrastructure development projects and receive regular income distributions. Example: An INVIT may invest in a portfolio of toll roads and bridges, collecting toll revenue from users. Investors in the INVIT receive dividends based on the cash flows generated by the infrastructure assets.",
  },
  {
    subheaders: "Convertibles",
    content:
      "Convertible securities, such as convertible bonds or convertible preferred stock, give investors the option to convert their securities into shares of common stock at a predetermined conversion ratio. They provide investors with the potential for capital appreciation while offering downside protection through fixed-income features. Example: A company issues convertible bonds that allow bondholders to convert their bonds into shares of the company's common stock at a conversion price of $50 per share. If the company's stock price exceeds $50, bondholders may choose to convert their bonds into stock to benefit from the appreciation.",
  },
  {
    subheaders: "Zero Coupon Bonds",
    content:
      "Zero coupon bonds are debt securities that do not pay periodic interest payments like traditional bonds. Instead, they are issued at a discount to their face value and redeemed at face value at maturity, providing investors with a return in the form of capital appreciation. Example: An investor purchases a zero coupon bond with a face value of $1,000 at a discounted price of $800. At maturity, the bond will be redeemed for its full face value of $1,000, resulting in a $200 capital gain for the investor.",
  },
  {
    subheaders: "Alternate Investment Fund (AIF)",
    content:
      "Alternative investment funds are privately pooled investment vehicles that invest in assets beyond traditional stocks, bonds, and cash. They may include hedge funds, private equity funds, real estate funds, or commodity funds. AIFs are subject to less stringent regulatory requirements compared to traditional mutual funds and are typically open to accredited investors. Example: A private equity fund raises capital from institutional investors and high-net-worth individuals to invest in privately held companies. The fund manager seeks to generate superior returns by actively managing and growing the portfolio of investments.",
  },
];

const GreatToKnow = () => {
  const { isDark } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("greatoknowTopics", JSON.stringify(topics));
  }, []);

  return (
    <div className='Start' style={{ color: isDark && "white" }}>
      <div className='main-content'>
        <div>
          <h1 style={{ fontWeight: "bold" }}>Great to Know Category</h1>
        </div>

        <div className='topics'>
          {topics.map((topic, index) => {
            return (
              <div className='topic'>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                    fontWeight: "bolder",
                    fontSize: "2rem",
                  }}
                >
                  {index + 1}{" "}
                  <span
                    className='line'
                    style={{
                      backgroundColor: isDark ? "#cfcfcf" : `${colors[index]}`,
                    }}
                  ></span>
                </div>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "1.3rem",
                    marginTop: 0,
                  }}
                >
                  {topic.subheaders}
                </p>
                <p className='kh-content'>{topic.content}</p>
                <div className='topicFooter'>
                  <p onClick={() => navigate(`module/${index + 1}`)}>
                    <Button
                      style={{ padding: "4px", color: isDark && "ghostwhite" }}
                      type='link'
                    >
                      View More
                    </Button>
                  </p>
                  {/* <p>Watch videos</p>
                  <p>Hindi</p> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GreatToKnow;
