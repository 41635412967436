import { useState, useEffect, useContext } from "react";
import { Layout, Row, Col, Button, Input, Space, Divider } from "antd";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import "@theme-toggles/react/css/Expand.css";
import { Expand } from "@theme-toggles/react";
import "primereact/resources/themes/lara-light-cyan/theme.css";

import "./App.css";
import logo from "../src/assets/logo.png";
import logoShort from "../src/assets/logoShort.png";
import LoginRouter from "./Router/loginRouter";
import "primeflex/primeflex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  QuestionCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  DashboardOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { ThemeContext } from "./context/ThemeContext";
import ChatBot from "./components/bondGPT/ChatBot";
const { Header, Sider, Content } = Layout;

const App = (props) => {
  const history = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [activemenuKey, setActiveMenuKey] = useState("Dashboard");
  const [openKeys, setOpenKeys] = useState([]);
  const [token, setToken] = useState(null);
  const [open, setOpen] = useState(false);
  const { isDark, toggleTheme } = useContext(ThemeContext);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleSignOut = () => {
    localStorage.clear();
    setToken(null);
    history("/login");
  };

  const handleMenuClick = (e) => {
    console.log("click ", e);
    console.log("key", e.keyPath[1]);
  };

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const showLoading = () => {
    setOpen(true);
  };

  const navItems = [
    { name: "Dashboard", link: "/d/dash", icon: "DashboardOutlined" },
    { name: "Knowledge Hub", link: "/d/knowledge", icon: "DashboardOutlined" },
    { name: "Values", link: "/d/values", icon: "QuestionCircleOutlined" },
    { name: "Leadership", link: "/d/leaders", icon: "QuestionCircleOutlined" },
    { name: "Blogs", link: "/d/blogs", icon: "QuestionCircleOutlined" },
    {
      name: "Testimonials",
      link: "/d/testimonials",
      icon: "QuestionCircleOutlined",
    },
    { name: "Contact Us", link: "/d/contact", icon: "SettingOutlined" },
    { name: "Admin Panel", link: "/admin", icon: "SettingOutlined" },
    { name: "Subscription", link: "/d/plans", icon: "SettingOutlined" },
    { name: "Orders", link: "/d/orders", icon: "SettingOutlined" },
    { name: "Payments", link: "/d/payments", icon: "SettingOutlined" },
  ];

  const navFooterItems = [
    { name: "Settings", link: "#", icon: "SettingOutlined" },
    {
      name: "Logout",
      link: "/d/orders",
      icon: "LogoutOutlined",
      fn: { handleSignOut },
    },
  ];

  const handleOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && !this.rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("authToken");
    if (!savedData) {
      const razorToken = localStorage.getItem("razorpayAuthToken");
      if (razorToken) {
        history("/d/orders");
      } else handleSignOut();
    }
  }, []);

  useEffect(() => {
    setActiveMenuKey(location.pathname.split("/").slice(0, 3).join("/"));

    return () => {
      setActiveMenuKey();
    };
  }, [location.pathname]);

  useEffect(() => {
    localStorage.setItem("theme", isDark);
  }, [isDark]);

  return (
    <>
      {token !== null ? (
        <LoginRouter />
      ) : (
        <Layout>
          <Sider
            style={{ backgroundColor: "black" }}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div className='demo-logo-vertical' />
            <Space className='logoSection'>
              {!collapsed ? (
                <img className='logo' src={logo} alt='logo' />
              ) : (
                <img className='logo' src={logoShort} alt='logo' />
              )}
            </Space>
            <ul className='drawerList'>
              {navItems.map((elem, index) => {
                return (
                  <Link
                    className='navLink'
                    to={elem.link}
                    onClick={() => setActiveMenuKey(elem.link)}
                  >
                    <div
                      className='navList'
                      style={{
                        border:
                          activemenuKey === elem.link && "2px solid #0acf83",
                        justifyContent: collapsed && "center",
                        paddingTop: collapsed && "10px",
                        paddingBottom: collapsed && "10px",
                      }}
                      onClick={elem.fn && handleSignOut}
                    >
                      {elem.icon === "DashboardOutlined" ? (
                        <DashboardOutlined
                          style={{
                            color: activemenuKey === elem.link && "#0ACF83",
                          }}
                        />
                      ) : elem.icon === "QuestionCircleOutlined" ? (
                        <QuestionCircleOutlined
                          style={{
                            color: activemenuKey === elem.link && "#0ACF83",
                          }}
                        />
                      ) : elem.icon === "SettingOutlined" ? (
                        <SettingOutlined
                          style={{
                            color: activemenuKey === elem.link && "#0ACF83",
                          }}
                        />
                      ) : (
                        <LogoutOutlined
                          style={{
                            color: activemenuKey === elem.link && "#0ACF83",
                          }}
                        />
                      )}

                      <li
                        style={{
                          color: activemenuKey === elem.link && "#0ACF83",
                        }}
                      >
                        {!collapsed ? elem.name : ""}
                      </li>
                    </div>
                  </Link>
                );
              })}
            </ul>

            <Divider style={{ backgroundColor: "#9598A0", margin: "14px 0" }} />

            {navFooterItems.map((elem) => {
              return (
                <div
                  className='navLink navList'
                  onClick={() => elem.name === "Logout" && handleSignOut()}
                  style={{
                    paddingTop: collapsed && "10px",
                    paddingBottom: collapsed && "10px",
                  }}
                >
                  {elem.name === "Settings" ? (
                    <SettingOutlined />
                  ) : (
                    elem.name === "Logout" && <LogoutOutlined />
                  )}

                  <li>{!collapsed && elem.name}</li>
                </div>
              );
            })}
          </Sider>

          <Layout style={{ height: "100vh" }}>
            <Header style={{ background: "#fff", padding: 0 }}>
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: "1rem",
                  backgroundColor: "black",
                }}
              >
                <Space>
                  <Button
                    type='text'
                    icon={
                      collapsed ? (
                        <MenuUnfoldOutlined style={{ color: "white" }} />
                      ) : (
                        <MenuFoldOutlined style={{ color: "white" }} />
                      )
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                      width: 64,
                      height: 64,
                    }}
                  />

                  <Button
                    style={{ margin: "5px" }}
                    type='primary'
                    shape='round'
                    title='Back '
                    icon={<LeftOutlined />}
                    onClick={() => history(-1)}
                  >
                    Go Back
                  </Button>
                </Space>

                <Space style={{ display: "flex", alignItems: "center" }}>
                  <Input placeholder='Search' style={{ width: "20vw" }} />

                  <div onClick={() => toggleTheme()}>
                    <Expand
                      duration={750}
                      style={{ color: "white", fontSize: "2rem" }}
                    />
                  </div>
                </Space>

                <Col span={20}>
                  <h2 style={{ marginLeft: "20px" }}>{props.header}</h2>
                </Col>
                <Col>
                  <div
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                    }}
                    onClick={handleSignOut}
                  ></div>
                </Col>
              </Row>
            </Header>
            <Content
              style={{
                padding: 10,
                background: isDark ? "#3f3f3f" : "white",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  padding: 10,
                  background: isDark ? "#3f3f3f" : "white",
                  minHeight: 500,
                }}
              >
                <ChatBot></ChatBot>
                <Outlet></Outlet>
                {/* <Button
                  type="primary"
                  onClick={showDrawer}
                  className="open-button"
                  icon={<WechatOutlined />}
                ></Button> */}
              </div>
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default App;
