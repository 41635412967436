import { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import { Dropdown, Space, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { ThemeContext } from "../../context/ThemeContext";
import "../../../src/CustomPagination.css";

const TradeRepositoryData = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [nsePrimaryTradeData, setNsePrimaryTradeData] = useState([]);
  const [nseSecondaryTradeData, setNseSecondaryTradeData] = useState([]);
  const [nseTradedOnCMData, setNseTradedOnCMData] = useState([]);
  const [nsePrimaryTradeColumn, setNsePrimaryTradeColumn] = useState([]);
  const [nseSecondaryTradeColumn, setNseSecondaryTradeColumn] = useState([]);
  const [nseTradedOnCMColumn, setNseTradedOnCMColumn] = useState([]);
  const [selectedItem, setSelectedItem] = useState("NSE Trade Repository"); // Initial label
  const [selectedKey, setSelectedKey] = useState("1-1"); // Initial label
  const [bseTradeData, setBseTradeData] = useState([]);
  const [bseTradeColumn, setBseTradeColumn] = useState([]);
  const { isDark } = useContext(ThemeContext);

  const components = {
    table: (props) => (
      <table
        {...props}
        style={{
          backgroundColor: isDark ? "#3f3f3f" : "white",
          color: isDark ? "white" : "black",
          border: isDark && "1px solid gray",
        }}
      >
        {props.children}
      </table>
    ),
    body: {
      row: ({ className, ...restProps }) => (
        <tr
          {...restProps}
          style={{
            backgroundColor: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        />
      ),
      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
    header: {
      row: (props) => (
        <tr
          {...props}
          style={{
            background: isDark ? "#494949" : "white",
            color: isDark ? "white" : "black",
          }}
        >
          {props.children}
        </tr>
      ),

      cell: (props) => (
        <th
          {...props}
          style={{
            color: isDark ? "white" : "black",
            background: isDark && "#494949 ",
            fontWeight: "bold",
            borderBottom: isDark && "1px solid gray",
          }}
        >
          {props.children}
        </th>
      ),
    },
  };

  const convertCamelCaseToTitle = (camelCase) => {
    return camelCase
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };
  useEffect(() => {
    const fetchPrimaryData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}/nse/trade/primary-issuance`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${token}`, // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        setNsePrimaryTradeData(responseData); // Set the fetched data into state

        // Generate columns dynamically based on the keys of the first object
        if (responseData.length > 0) {
          const firstItem = responseData[0];
          const generatedColumns = Object.keys(firstItem).map((key) => ({
            title: convertCamelCaseToTitle(key), // Convert camelCase to a human-readable title
            dataIndex: key,
            key: key,
          }));
          setNsePrimaryTradeColumn(generatedColumns);
        }
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };
    const fetchSecondaryData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(
          `${baseUrl}/nse/trade/secondary-issuance`,
          {
            headers: {
              "ngrok-skip-browser-warning": "application/json",
              Authorization: `Bearer ${token}`, // Set Authorization header with token
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        setNseSecondaryTradeData(responseData); // Set the fetched data into state

        // Generate columns dynamically based on the keys of the first object
        if (responseData.length > 0) {
          const firstItem = responseData[0];
          const generatedColumns = Object.keys(firstItem).map((key) => ({
            title: convertCamelCaseToTitle(key), // Convert camelCase to a human-readable title
            dataIndex: key,
            key: key,
          }));
          setNseSecondaryTradeColumn(generatedColumns);
        }
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };
    const fetchTradedOnCMData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(
          `${baseUrl}/nse/trade/all-bonds-traded-on-cm`,
          {
            headers: {
              "ngrok-skip-browser-warning": "application/json",
              Authorization: `Bearer ${token}`, // Set Authorization header with token
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        setNseTradedOnCMData(responseData); // Set the fetched data into state

        // Generate columns dynamically based on the keys of the first object
        if (responseData.length > 0) {
          const firstItem = responseData[0];
          const generatedColumns = Object.keys(firstItem).map((key) => ({
            title: convertCamelCaseToTitle(key), // Convert camelCase to a human-readable title
            dataIndex: key,
            key: key,
          }));
          setNseTradedOnCMColumn(generatedColumns);
        }
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };
    const fetchBseTradeData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        const response = await fetch(`${baseUrl}/bse/otc`, {
          headers: {
            "ngrok-skip-browser-warning": "application/json",
            Authorization: `Bearer ${token}`, // Set Authorization header with token
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const responseData = await response.json();
        setBseTradeData(responseData); // Set the fetched data into state

        // Generate columns dynamically based on the keys of the first object
        if (responseData.length > 0) {
          const firstItem = responseData[0];
          const generatedColumns = Object.keys(firstItem).map((key) => ({
            title: convertCamelCaseToTitle(key), // Convert camelCase to a human-readable title
            dataIndex: key,
            key: key,
          }));
          setBseTradeColumn(generatedColumns);
        }
      } catch (error) {
        console.error(`Error fetching announcement data:`, error);
        // Handle error state or display a message to the user
      }
    };
    fetchPrimaryData();
    fetchSecondaryData();
    fetchTradedOnCMData();
    fetchBseTradeData();
  }, []);
  const items = [
    {
      key: "1",
      type: "group",
      label: "Primary Issuance",
      children: [
        {
          key: "1-1",
          label: "NSE Trade Repository",
        },
      ],
    },
    {
      key: "2",
      type: "group",
      label: "Secondary Issuance",
      children: [
        {
          key: "2-1",
          label: "Combined NSE & BSE",
        },
      ],
    },
    {
      key: "3",
      type: "group",
      label: "Bonds Traded on CM Platform",
      children: [
        {
          key: "3-1",
          label: "All (NSE & BSE)",
        },
      ],
    },
    {
      key: "4",
      type: "group",
      label: "BSE",
      children: [
        {
          key: "4-1",
          label: "OTC Trade Data",
        },
      ],
    },
  ];

  const handleMenuClick = (e) => {
    let selected;
    let key;
    // Iterate through each group to find the matching key in the children
    items.forEach((group) => {
      if (group.children) {
        group.children.forEach((child) => {
          if (child.key === e.key) {
            selected = child.label;
            key = child.key;
          }
        });
      }
    });

    // Set the selected item if found
    if (selected) {
      setSelectedItem(selected);
      setSelectedKey(key);
      console.log(key);
    }
  };

  return (
    <div>
      {" "}
      <h1 className='isin-header'>
        Trade Repository-
        <a
          href='https://www.nseindia.com/market-data/debt-market-trade-repository'
          target='_blank'
        >
          NSE LINK
        </a>{" "}
        -
        <a
          href='https://www.bseindia.com/markets/debt/CorporateBonds.aspx?Flag=1'
          target='_blank'
        >
          BSE LINK
        </a>{" "}
      </h1>
      <div className='my-3'>
        <Dropdown
          menu={{
            items,
            selectable: true,
            defaultSelectedKeys: ["3"],
            onClick: handleMenuClick,
          }}
        >
          <Typography.Link>
            <Space>
              {selectedItem}
              <DownOutlined />
            </Space>
          </Typography.Link>
        </Dropdown>
      </div>
      {selectedKey == "1-1" && (
        <Table
          className='custom-pagination'
          components={components}
          columns={nsePrimaryTradeColumn}
          dataSource={nsePrimaryTradeData}
          rowKey='id'
        />
      )}
      {selectedKey == "2-1" && (
        <Table
          className='custom-pagination'
          components={components}
          columns={nseSecondaryTradeColumn}
          dataSource={nseSecondaryTradeData}
          rowKey='id'
        />
      )}
      {selectedKey == "3-1" && (
        <Table
          className='custom-pagination'
          components={components}
          columns={nseTradedOnCMColumn}
          dataSource={nseTradedOnCMData}
          rowKey='id'
        />
      )}
      {selectedKey == "4-1" && (
        <Table
          className='custom-pagination'
          components={components}
          columns={bseTradeColumn}
          dataSource={bseTradeData}
          rowKey='id'
        />
      )}
    </div>
  );
};

export default TradeRepositoryData;
