import React, { useContext, useEffect, useState } from "react";
import "../shared/staticStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import App from "../../App";
import KnowledgeHubImg from "../../assets/reading.svg";
import { ThemeContext } from "../../context/ThemeContext";
import CardsSlider from "../CardsSlider";

const Home = () => {
  const navigate = useNavigate();
  const { isDark } = useContext(ThemeContext);

  const cardsInfo = [
    {
      name: "Domain Concepts",
      articles: "6",
      info: "From foundational theories to advanced topics, explore the core concepts that drive our industry forward.",
      navigate: "domain",
    },
    {
      name: "Market Infrastructure",
      articles: "6",
      info: "Explore the intricate network of systems and mechanisms that form the backbone of our market infrastructure.",
      navigate: "",
    },
    {
      name: "News Room",
      articles: "6",
      info: "Stay informed with the latest news, updates, and developments shaping our industry landscape.",
      navigate: "",
    },
    {
      name: "Miscellaneous",
      articles: "6",
      info: "From tips and tricks to intriguing discoveries, our Miscellaneous section offers a wealth of knowledge to satisfy your curiosity.",
      navigate: "",
    },
  ];

  // useEffect(() => {
  //   const theme = localStorage.getItem("theme");
  //   console.log(theme);
  //   setIsDark(theme);
  // }, [localStorage.getItem("theme")]);

  return (
    <div
      className='Start'
      style={{
        backgroundColor: isDark ? "#3f3f3f" : "white",
        color: isDark ? "white" : "#3f3f3f",
      }}
    >
      <div className='main-content'>
        <div>
          <h1 className='header'>Knowledge Hub</h1>
          <div className='cont'>
            <p className='kh-description'>
              At SimplyBond, we're dedicated to providing you with valuable
              insights and resources to navigate the complexities of our
              industry. Whether you're a newcomer seeking foundational knowledge
              or an industry veteran looking to stay updated, our Knowledge Base
              offers a wealth of information tailored to your needs. Dive into
              our diverse range of topics and discover the latest trends,
              insights, and developments shaping our industry. From essential
              concepts to advanced strategies, SimplyBond is your go-to
              destination for comprehensive industry knowledge.
            </p>
            <img src={KnowledgeHubImg} alt='image' className='kh-img' />
          </div>
        </div>

        <CardsSlider cardsInfo={cardsInfo} navigate={navigate} />
      </div>
    </div>
  );
};
export default Home;
