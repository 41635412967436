import { Routes, Route } from "react-router-dom";
import RatingNew from "../components/RatingNew";
import Rational from "../components/Rational";
import News from "../components/News";
import ISIN from "../components/ISIN";
import Login from "../components/Login";
import SignUp from "../components/Signup";
import Verify from "../components/VerifyUser";
import Financial from "../components/financial";
import GreatToKnow from "../components/knowlegde-hub/greatToKnow";
import GoodToKnow from "../components/knowlegde-hub/goodToKnow";
import MustKnow from "../components/knowlegde-hub/mustKnow";
import DomainConcepts from "../components/knowlegde-hub/domainConcepts";
import Home from "../components/knowlegde-hub/home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import OurValues from "../components/OurValues";
import "../App.css"; /* Importing the global CSS file */
import Leadership from "../components/Leadership/Leadership";
import ContactUs from "../components/ContactUs";
import Testimonials from "../components/testimonials";
import LeaderDetail from "../components/Leadership/Leader-Detail";
import BlogDetails from "../components/Blogs/BlogDetails";
import BlogDashboard from "../components/Blogs/Blog-Dashboard";
import Landing from "../components/Landing/Landing";
import LandingPageLayout from "../components/Landing/LandingPageLayout";
import App from "../App";
import Admin from "../components/Admin/Admin";
import Module from "../components/knowlegde-hub/module";

import CorporateBonds from "../components/bot/CorporateBonds";
import Bot1 from "../components/bot/bot1";
import PDF from "../components/bot/pdf";
import BondsTradedInCapitalMarket from "../components/nse/BondsTradedInCapitalMarket";
import DebtISINList from "../components/nse/DebtISINList";
import NSEDebtPdfData from "../components/nse/NSEDebtPdfData";
import DebtAnnouncement from "../components/nse/DebtAnnouncement";
import IssuerDashboard from "../components/Dashboard/IssuerDashboard";
import Dashboard from "../components/Dashboard/Dashboard";
import LoginRazorpay from "../components/razorpay/LoginRazorpay";
import Plans from "../components/razorpay/plans";
import PaymentDetails from "../components/razorpay/PaymentDetails";
import OrderDetails from "../components/razorpay/OrderDetails";
import AdminApprovalComponent from "../components/Admin/AdminApprovalComponent";
import NewsApproval from "../components/Admin/NewsAprroval";
import GovernmentSchemesDashboard from "../components/Government-Schemes/RBIStateGovSecurities";
import RBISavingBonds from "../components/Government-Schemes/RBISavingBonds";
import RBIGovSecurities from "../components/Government-Schemes/RBIGovSecurities";
import RBIStateGovSecurities from "../components/Government-Schemes/RBIStateGovSecurities";

const topicsData = JSON.parse(localStorage.getItem("topicsData"));
const greatoknowTopics = JSON.parse(localStorage.getItem("greatoknowTopics"));
const goodToKnowTopics = JSON.parse(localStorage.getItem("goodToKnowTopics"));
const colors = JSON.parse(localStorage.getItem("colors"));

const Routers = (props) => (
  <>
    <Routes>
      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/approval" element={<AdminApprovalComponent />} />
      <Route path="/admin/approval/news" element={<NewsApproval />} />

      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/login-razor" element={<LoginRazorpay />} />

      {/* wilcard route */}

      {/* <Route path="/knowledge" element={<Home />} /> */}

      <Route path="/" element={<LandingPageLayout />}>
        <Route path="" element={<Landing />} />

        <Route path="home" element={<Landing />} />
        <Route path="values" element={<OurValues />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="leaders" element={<Leadership />} />
        <Route path="leaders/leader/:id" element={<LeaderDetail />} />
        <Route path="testimonials" element={<Testimonials />} />
        <Route path="blogs" element={<BlogDashboard />} />
        <Route path="blog-details/:id" element={<BlogDetails />} />
        <Route path="knowledge" element={<Home />} />
        <Route path="knowledge/domain" element={<DomainConcepts />} />
        <Route path="knowledge/domain/mustknow" element={<MustKnow />} />
        <Route path="knowledge/domain/goodtoknow" element={<GoodToKnow />} />
        <Route path="knowledge/domain/greatoknow" element={<GreatToKnow />} />
        <Route path="*" element={<Landing />} />
        <Route path="bot1" element={<Bot1 />} />
        <Route path="pdf" element={<PDF />} />
        <Route path="cb" element={<CorporateBonds />} />
        <Route path="pdf" element={<PDF />} />
      </Route>
      <Route path="/d" element={<App />}>
        <Route path="" element={<Dashboard />} />
        <Route path="rating" element={<RatingNew />} />
        <Route path="rational" element={<Rational />} />
        <Route path="news" element={<News />} />
        <Route path="isin" element={<ISIN />} />
        <Route path="bse" element={<Financial />} />
        <Route path="dash" element={<Dashboard />} />
        <Route path="values" element={<OurValues />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="leaders" element={<Leadership />} />
        <Route path="leaders/leader/:id" element={<LeaderDetail />} />
        <Route path="testimonials" element={<Testimonials />} />
        <Route path="blogs" element={<BlogDashboard />} />
        <Route path="blog-details/:id" element={<BlogDetails />} />
        <Route path="knowledge" element={<Home />} />
        <Route path="knowledge/domain" element={<DomainConcepts />} />
        <Route path="gov" element={<GovernmentSchemesDashboard />} />
        <Route path="gov/1" element={<RBISavingBonds />} />
        <Route path="gov/2" element={<RBIStateGovSecurities />} />
        <Route path="gov/3" element={<RBIGovSecurities />} />
        <Route
          path="knowledge/domain/mustknow/module/:id"
          element={<Module data={topicsData} colors={colors} />}
        />
        <Route
          path="knowledge/domain/greatoknow/module/:id"
          element={<Module data={greatoknowTopics} colors={colors} />}
        />
        <Route
          path="knowledge/domain/goodtoknow/module/:id"
          element={<Module data={goodToKnowTopics} colors={colors} />}
        />
        <Route path="knowledge/domain/mustknow" element={<MustKnow />} />
        <Route path="knowledge/domain/goodtoknow" element={<GoodToKnow />} />
        <Route path="knowledge/domain/greatoknow" element={<GreatToKnow />} />
        <Route path="debt" element={<DebtISINList />} />
        <Route path="debt/info" element={<NSEDebtPdfData />} />
        <Route path="debt-announcement" element={<DebtAnnouncement />} />
        <Route path="capital-market" element={<BondsTradedInCapitalMarket />} />
        <Route path="cb" element={<CorporateBonds />} />
        <Route path="dash/details" element={<IssuerDashboard />} />
        <Route path="plans" element={<Plans />} />
        <Route path="payments" element={<PaymentDetails />} />
        <Route path="orders" element={<OrderDetails />} />

        <Route path="*" element={<Dashboard />} />
      </Route>

      {/* Add additional routes as needed */}
      {/* <Route exact path="/" component={Dashboard} />
      <Route exact path="/setting" component={Setting} />
      <Route exact path="/help" component={Help} /> */}
    </Routes>
  </>
);

export default Routers;
